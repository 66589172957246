<script>
import { BbTransactions } from '@bigbank/interface-components'
import api from '@/modules/loan/api'
import dayjs from 'dayjs'
import {
  TransactionSettingsItem
} from '@bigbank/dc-common/enums/loan.enums'
import { CountryChannel } from '@bigbank/dc-common/config'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import { isNull } from 'lodash'
import { CREDIT_CARD } from '@/const'
import {
  AccountStatementFileType
} from '@bigbank/dc-common/enums/credit-card.enums'
import '@bigbank/interface-components/dist/svg/illustration/credit-card'
import '@bigbank/interface-components/dist/svg/illustration/virtual-card'
import '@bigbank/interface-components/dist/svg/ui/chevron-down.svg'
import { mapState, mapActions } from 'pinia'
import CardTypeTranslationMixin from '@credit-card/components/mixins/CardTypeTranslationMixin'
import { transformFormDataIntoSearchRequestBody } from '@credit-card/components/util/transactions.util'
import { EMPTY_FILTERS_FORM_DATA } from './credit-card.const'
import MainTransactionFilter from '@/components/transactions/MainTransactionFilter'
import TransactionsWrapper from '@/components/transactions/TransactionsWrapper.vue'
import { useRootStore } from '@/store/root'
import { useCreditCardStore } from '@/store/modules/credit-card'
import { useLoanStore } from '@loan/store/loanStore'

dayjs.extend(dayjsPluginUTC)

export default {
  name: 'credit-card-transactions-list',
  extends: TransactionsWrapper,
  components: { BbTransactions, MainTransactionFilter },
  props: {
    contractId: { required: false },
    contractNumber: { required: false, type: String },
    contractCurrencyCode: { required: false, type: String }
  },
  mixins: [CardTypeTranslationMixin],
  data () {
    return {
      filters: Object.assign(
        { form: JSON.parse(JSON.stringify(EMPTY_FILTERS_FORM_DATA)) },
        { formPreviousState: null }
      ),
      totalTransactionDisplayed: CREDIT_CARD.TRANSACTIONS_PER_PAGE,
      transactionsPerPage: CREDIT_CARD.TRANSACTIONS_PER_PAGE
    }
  },
  computed: {
    ...mapState(useLoanStore, [
      'featureFlags',
      'loading'
    ]),
    ...mapState(useCreditCardStore, [
      'creditCards',
      'creditCardAccountStatement',
      'creditCardTransactionsSettings',
      'creditCardTransactionsSearchRequest'
    ]),
    ...mapState(useRootStore, {
      rootFeatureFlags: 'featureFlags',
      isMobile: 'isMobile',
      locale: 'locale',
      channel: 'channel'
    }),
    translationsOverrides () {
      return {
        // Credit card transactions
        noTransactions: this.$pgettext('credit_card_transactions', 'Unfortunately there are no transactions or reservations matching your request. Try another one.'),
        noTransactionsSimpleState: this.$pgettext('credit_card_transactions', 'As soon as you have transactions, they will be displayd here.'),
        disclaimerTransactionVisibleForNyears: this.$pgettext('credit_card_transactions', 'You can see only past 2 years of transactions')
      }
    },
    computedAccountStatement () {
      return this.creditCardAccountStatement
    },
    isAvailableBalanceVisible () {
      return !this.rootFeatureFlags.disableCreditCardTransactionsAvailableAndStartingBalance
    },
    isStartingBalanceVisible () {
      return !this.rootFeatureFlags.disableCreditCardTransactionsAvailableAndStartingBalance
    },
    isReservationsVisible () {
      if (!this.filters.formPreviousState) {
        return true
      }
      return !dayjs(this.filters.formPreviousState.endDate).isBefore(dayjs(), 'day')
    },
    isUpdateStatementButtonDisabled () {
      const { startDate, endDate, advancedSearchFilters } = this.filters.form
      const isDatePickerFilled = startDate && endDate && dayjs(startDate).isValid() && dayjs(endDate).isValid()
      const isSomeOfAdvancesFilterEmpty = advancedSearchFilters
        .some((filter) => (filter.filterValue ?? '').trim().length === 0)

      return this.isLoading || !isDatePickerFilled || (isSomeOfAdvancesFilterEmpty && advancedSearchFilters.length > 1)
    },

    downloadableFileTypes () {
      return [
        {
          value: AccountStatementFileType.PDF,
          isDisabled: false
        },
        {
          value: AccountStatementFileType.ASICE,
          isDisabled: false,
          isHidden: [CountryChannel.LT].includes(this.channel)
        }
      ].filter((fileType) => !fileType.isHidden)
    },
    isSimpleEmptyStateShown () {
      return !this.contractId
    },
    isDownloadButtonShown () {
      return this.featureFlags.enableCreditCardAccountStatementDownload && !this.isLoading && this.contractId
    },
    isDownloadButtonDisabled () {
      return this.isDownloadInProgress || this.filters.form.startDate === null || this.filters.form.endDate === null
    },
    mainTransactionFilterDropdownItems () {
      return this.creditCards.map((card) => ({
        id: card.id,
        type: (this.cardTypeLabel(card) ?? card.type).replace('Visa', 'VISA'),
        icon: {
          [CREDIT_CARD.TYPE.VIRTUAL]: 'illustration-virtual-card',
          [CREDIT_CARD.TYPE.PHYSICAL_AND_VIRTUAL]: 'illustration-credit-card'
        }[card.type] ?? 'illustration-credit-card',
        digits: `*${card.maskedPan.replace(/\*/g, '')}`
      }))
    },
    transactionSettings () {
      return this.creditCardTransactionsSettings
    }
  },
  methods: {
    ...mapActions(useCreditCardStore, [
      'updateCreditCardTransactionsSearchRequest',
      'fetchCreditCardAccountStatement'
    ]),
    resetFilters () {
      this.filters = Object.assign(
        { form: JSON.parse(JSON.stringify(EMPTY_FILTERS_FORM_DATA)) },
        { formPreviousState: null }
      )

      this.setPeriod(this.creditCardTransactionsSettings[TransactionSettingsItem.FilteringPeriodByDefault])
    },
    async onStatementDownloadRequested (fileType) {
      try {
        const searchRequest = transformFormDataIntoSearchRequestBody(this.filters.form, this.transactionsPerPage, 1)
        this.setIsDownloadInProgress(true)
        await api.downloadCreditCardAccountStatement(this.contractId, searchRequest, fileType.value)
      } catch (err) {
        console.error(err)
      }
      this.setIsDownloadInProgress(false)
    },
    async fetchAccountStatement ({ pageNumber }) {
      if (!this.contractId) {
        return
      }

      const searchRequest = transformFormDataIntoSearchRequestBody(
        this.filters.form,
        this.transactionsPerPage,
        pageNumber
      )

      const diff = this.getDifference(this.creditCardTransactionsSearchRequest, searchRequest)
      const appendResults = pageNumber > 1 && diff.includes('pageNumber') && diff.length === 1

      this.updateCreditCardTransactionsSearchRequest(searchRequest)
      await this.fetchCreditCardAccountStatement({ contractId: this.contractId }, appendResults)
    },
    onTransactionItemClicked (index) {
      if (!this.featureFlags.enableCreditCardTransactionDetailsView) {
        return
      }

      const transaction = this.computedAccountStatement.transactions[index] ?? null

      if (isNull(transaction)) {
        throw new Error('Unable to open transaction, try again later')
      }

      this.$router.push(`/my-credit-card/transactions/${transaction.id}`)
    }
  }
}
</script>
