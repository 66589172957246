<template>
  <bb-dropdown
    gap="10"
    class="w-100"
  >
    <template #trigger>
      <bb-button
        size="lg"
        inverted="filled"
        color="black"
        display="block"
        icon-after="ui-chevron-down"
        icon-after-fixed
        class="justify-content-start p-x-15 bg-gray-10 border-gray-20 border-width-1 border-style-solid"
      >
        <div
          class="d-flex justify-content-center w-100"
          style="position: absolute; left: 0; top: -9px;"
        >
          <bb-label
            size="18"
            :value="labelTranslation"
            :class="`bg-${labelColor} border-gray-20 border-width-1 border-style-solid f-gotham-medium f-tiny`"
          />
        </div>
        <div class="f-small align-left m-r-15">
          {{ selectedAccount.name }}
        </div>
      </bb-button>
    </template>
    <div class="p-15">
      <span class="f-tiniest f-medium f-color-gray-90">{{ translations.privateAccounts }}</span>
      <bb-dropdown-item
        v-for="(account, index) in privateAccounts"
        :key="`${account.name}-${index}`"
        @click="onAccountChange(account)"
        type="button"
        :label="account.name"
        :class="`p-l-0 border-none ${isAccountActive(account) ? 'f-color-mint f-strong' : 'f-color-gray'}`"
      />
      <span class="f-tiniest f-medium f-color-gray-90">{{ translations.businessAccounts }}</span>
      <bb-dropdown-item
        v-for="(account, index) in businessAccounts"
        :key="`${account.name}-${index}`"
        @click="onAccountChange(account)"
        type="button"
        :label="account.name"
        :class="`p-l-0 border-none ${isAccountActive(account) ? 'f-color-mint f-strong' : 'f-color-gray'}`"
      />
    </div>
  </bb-dropdown>
</template>

<script>
import { Language } from '@bigbank/dc-common/config'
import { LegalPrivateCode } from '@bigbank/dc-common/clients/http/crm-service/crm-service.enums'
import { mapState } from 'pinia'
import { useRootStore } from '../store/root'
import { cancelAllRequests } from '../plugins/utils'

export default {
  name: 'account-switcher',
  data () {
    return {
      selectedAccount: {
        name: null,
        customerId: null
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'relatedEntities', 'customerId', 'language']),
    translations () {
      return {
        private: this.$pgettext('corporate_account_switcher', 'private'),
        business: this.$pgettext('corporate_account_switcher', 'business'),
        privateAccounts: this.$pgettext('corporate_account_switcher', 'Private accounts'),
        businessAccounts: this.$pgettext('corporate_account_switcher', 'Business accounts')
      }
    },
    labelColor () {
      return this.isCompany ? 'navy' : 'mint'
    },
    labelTranslation () {
      return this.isCompany ? this.translations.business : this.translations.private
    },
    businessAccounts () {
      return this.relatedEntities.filter(customer => customer.legalPrivateCode === LegalPrivateCode.LEGAL)
    },
    privateAccounts () {
      return this.relatedEntities.filter(customer => customer.legalPrivateCode === LegalPrivateCode.PRIVATE)
    }
  },
  methods: {
    onAccountChange (newAccount) {
      this.selectedAccount = {
        name: newAccount.name,
        customerId: newAccount.customerId
      }

      const isBusinessAccount = !!this.businessAccounts.find((account) => account.customerId === newAccount.customerId)

      const languageOverride = isBusinessAccount && this.language === Language.ru
        ? Language.en
        : this.language

      window.stop()
      cancelAllRequests()
      window.location.href = `/gw/represent/${newAccount.customerId}`
    },
    isAccountActive (account) {
      return account.customerId === this.selectedAccount.customerId
    }
  },
  mounted () {
    const currentUser = this.relatedEntities.find(({ customerId }) => String(this.customerId) === String(customerId))

    this.selectedAccount = {
      name: currentUser?.name,
      customerId: currentUser?.customerId
    }
  }
}
</script>
