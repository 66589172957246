import ConsumerLoanPayBack from '@/modules/loan/views/ConsumerLoanPayBack.vue'
import RevolvingLoanPayback from '@/modules/loan/views/revolving/PayBack.vue'
import { mapState } from 'pinia'
import { useLoanStore } from '@/modules/loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  computed: {
    ...mapState(useLoanStore, ['featureFlags']),
    isLoan () {
      return [
        InternalLoanType.LOAN,
        InternalLoanType.HOUSING,
        InternalLoanType.REAL_ESTATE,
        InternalLoanType.LEASING
      ].includes(this.contract.typeCode)
    },
    isRevolvingLoan () {
      return [InternalLoanType.REVOLVING].includes(this.contract.typeCode)
    },
    computedInvoiceDetailsModal () {
      if (this.isLoan) {
        return ConsumerLoanPayBack
      } else {
        if (this.isRevolvingLoan) {
          return RevolvingLoanPayback
        }
      }
    }
  }
}
