<template>
  <signing-modal
    :visible="visible"
    ref="signing"
    :contract="contract"
    :initMethod="initSigning"
    @signingSuccessful="onSuccessfulSigning"
    @signingCancelled="emitModalClosedEvent"
  />
</template>

<script>
import { CreditCardSigningAction } from '@bigbank/dc-common/enums/credit-card.enums'
import SigningModal from '@/modules/loan/components/signing/SigningModal'
import api from '@/modules/loan/api'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'
import { mapActions, mapState } from 'pinia'
import { useCreditCardStore } from '@/store/modules/credit-card'

export default {
  name: 'credit-card-unblock-modal',
  components: {
    SigningModal
  },
  mixins: [CreditCardSigningMixin],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    maskedPan: { required: true, type: String }
  },
  computed: {
    ...mapState(useCreditCardStore, ['creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    translations () {
      return {
        cardSuccessfullyUnblocked: this.$gettextInterpolate(
          this.$pgettext('credit_card_block', 'Your credit card %{maskedPan} is successfully unblocked'),
          { maskedPan: this.maskedPan }
        )
      }
    }
  },
  methods: {
    ...mapActions(useCreditCardStore, ['refreshCreditCard']),
    setSigningRequestId (id) { this.signingRequestId = id },
    emitModalClosedEvent () {
      this.$emit('onModalClosed')
    },
    async onSuccessfulSigning () {
      const signingStatusResponse = await api.getCreditCardSigningStatusByAction(
        CreditCardSigningAction.Unblock,
        this.cardId,
        this.signingRequestId
      )

      if (!signingStatusResponse?.isSigningCompleted ?? false) {
        throw new Error('Signing is not completed!')
      }

      await this.refreshCreditCard(this.cardId)
      this.$notify({ text: this.translations.cardSuccessfullyUnblocked, duration: 5000 })
      this.emitModalClosedEvent()
    },
    async initSigning () {
      const srResponse = await api.initCreditCardSigningRequestByAction(
        CreditCardSigningAction.Unblock,
        this.contract.id,
        this.cardId
      )
      this.setSigningRequestId(srResponse.signingRequestId)

      return {
        method: srResponse.method,
        signingRequestId: srResponse.signingRequestId
      }
    }
  },
  mounted () {
    if (this.hasReturnedAfterSigning()) {
      this.setSigningRequestId(this.signingRequestIdFromQuery)
    } else {
      this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
    }
  }
}
</script>
