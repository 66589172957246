<template>
  <div class="credit-card-replace-modal">
    <bb-modal
      :visible="isModalVisible"
      flavor="card"
      @close="onModalClosed"
      class="noselect text-center"
      centerVertically
      fullMobile
    >
      <h5 class="text-center modal-title">{{ modalTitle }}</h5>
      <h4 class="text-center f-gotham-book m-t-30 modal-description" v-sanitize.basic="translations.description" />
      <h4 class="text-center f-gotham-book m-t-40 f-default f-color-gray-70">{{ translations.selectReason }}</h4>
      <bb-detailed-radio
        class="text-left"
        v-model="reason"
        name="block-reason"
        :options="radioButtonOptions"
      />
      <div class="bg-gray-10 p-x-15 p-y-15 m-t-15 text-left f-smallest f-color-navy">
        <customer-address-text-label :translations="translations"/>
      </div>
      <div slot="footer" :class="!isMobile ? 'p-x-30 p-b-30' : ''">
        <bb-button
          name="submit-replace"
          v-bind="submitButtonProps"
          @click="onReplaceButtonClick"
        />
      </div>
    </bb-modal>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/cvv'
import CardTypeTranslationMixin from '../mixins/CardTypeTranslationMixin'
import { CardBlockReason } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import CustomerAddressTextLabel from '@/modules/account/components/CustomerAddressTextLabel'
import CreditCardModalContentMixin from '@credit-card/components/mixins/CreditCardModalContentMixin'
import CustomerAddressTextLabelMixin from '@/modules/account/components/mixins/CustomerAddressTextLabelMixin'
import { mapActions } from 'pinia'
import { useCreditCardStore } from '@/store/modules/credit-card'

export default {
  name: 'credit-card-replace-modal',
  components: { CustomerAddressTextLabel },
  mixins: [
    CardTypeTranslationMixin,
    CreditCardModalContentMixin,
    CustomerAddressTextLabelMixin
  ],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    card: { required: true, type: Object }
  },
  data () {
    return {
      isLoading: false,
      reason: null,
      orderNewCardCheckbox: false,
      isModalVisible: this.visible
    }
  },
  computed: {
    translations () {
      return {
        modalTitle: this.$pgettext('credit_card_replace_title', 'Replace the card'),
        replaceCardButton: this.$pgettext('credit_card_replace_button', 'Replace card'),
        selectReason: this.$pgettext('credit_card_replace', 'Select the reason:'),
        addressTooltip: this.$pgettext('credit_card_replace', 'You can change the contact address under your data in self-service'),
        replace: this.$pgettext('credit_card_replace', 'I want to replace credit card'),
        replaceLine1: this.$gettextInterpolate(this.$pgettext('credit_card_block', 'Card replacement fee is <strong>%{fee} EUR</strong>. Money will be withdrawn from your limit.'), {
          fee: this.replacementFee
        }),
        replaceLine2: this.$pgettext('credit_card_replace', 'The new card will have a new PIN code and a card number. Active card will be blocked and we will send a new card to your:'),
        replaceLine3: this.$gettextInterpolate(this.$pgettext('credit_card_replace', 'Contact address - <strong>%{address}</strong>'), {
          address: this.customerContactAddress
        }),
        description: this.$gettextInterpolate(this.$pgettext('credit_card_replace', 'You want to replace <strong>%{cardType} %{maskedPan}</strong> because:'), {
          cardType: this.cardTypeLabel(this.card),
          maskedPan: this.card.maskedPan
        }),
        options: {
          [CardBlockReason.LostOrStolen]: this.$pgettext('credit_card_replace', 'Card is lost or stolen'),
          [CardBlockReason.Fraud]: this.$pgettext('credit_card_replace', 'Card CVV or PIN isn\'t suitable'),
          [CardBlockReason.Inactive]: this.$pgettext('credit_card_replace', 'Card is damaged/not working')
        },
        editAddress: this.$pgettext('credit_card_replace', 'Edit contact address')
      }
    },
    radioGroupOptions () {
      return [CardBlockReason.Fraud, CardBlockReason.Inactive, CardBlockReason.LostOrStolen]
    },
    submitButtonText () {
      return this.translations.replaceCardButton
    }
  },
  methods: {
    ...mapActions(useCreditCardStore, ['blockCreditCard']),
    setModalVisible (flag) { this.isModalVisible = flag },
    async onReplaceButtonClick () {
      this.setLoading(true)
      this.setOrderNewCardCheckbox(false)
      try {
        await this.blockCreditCard({
          contractId: this.contract.id,
          cardId: this.cardId,
          reason: this.reason,
          orderNewCard: !!this.orderNewCardCheckbox
        })

        this.setModalVisible(false)

        this.$emit('onReplaceClick')
      } finally {
        this.setLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-card-replace-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    color: $black;
    font-size: $default-font-size;
  }
}
</style>
