import { request, downloadFile } from '@/plugins/utils'
import { getUrlWithQueryParams } from '@/plugins/urlHelpers'
import { DepositType } from '@deposits/const'

class Api {
  constructor () {
    this.cache = {}
  }

  async getAndCache (key, getter, opts) {
    opts = Object.assign({ ttl: 5 * 60 * 1000 }, opts)
    const cacheValue = this.cache[key]

    if (!cacheValue || cacheValue.created < Date.now() - opts.ttl) {
      this.cache[key] = {
        created: Date.now(),
        value: await getter()
      }
    }

    return this.cache[key].value
  }

  getAccountInfo () {
    return request('/deposit/api/deposit/account', {})
  }

  getDepositDetails (id) {
    return request(`/deposit/api/deposit/${id}/details`, {})
  }

  getDepositOffer (id) {
    return request(`/deposit/api/deposit/header/${id}/offer`, {})
  }

  getDepositsCount () {
    return request('/deposit/api/deposits/count', {})
  }

  getDepositPaymentInfo (id, amount) {
    return request(`/deposit/api/deposit/header/${id}/payment-info?amount=${amount}`, {})
  }

  async getTransferDetails (id) {
    return this.getAndCache('getTransferDetails_' + id, () => {
      return request(`/deposit/api/deposit/${id}/transfer-details`, {})
    })
  }

  getAccruedInterest (id) {
    return request(`/deposit/api/deposit/${id}/accrued-interest`, {})
  }

  getTotalAccruedInterest (productType) {
    return request(`/deposit/api/deposit/accrued-interest/${productType}`, {})
  }

  getSchedule (id) {
    return request(`/deposit/api/deposit/${id}/schedule`, {})
  }

  getDocuments (id) {
    return request(`/deposit/api/deposit/${id}/documents`, {})
  }

  getUnsignedAgreement (id) {
    return request(`/deposit/api/deposit/${id}/unsigned-agreement`, {})
  }

  async getAccountTypes (agreementTypeCode = null) {
    let accountTypes = await request('/deposit/api/account/types', {
      method: 'get',
      errHandlerOpts: {
        throwOnAllErrors: true
      }
    })

    if (agreementTypeCode) {
      accountTypes = accountTypes.filter(accountType => accountType.agreementTypeCode === agreementTypeCode)
    }

    return accountTypes
  }

  async getAccountDefaults (accountTypeCode, periodCode) {
    let url = `/deposit/api/account/defaults/all?accountTypeCode=${accountTypeCode}`

    if (periodCode) {
      url += `&periodCode=${periodCode}`
    }

    return request(url, {})
  }

  async getAccountInterestParameters (amount, period, periodCode, interestFrequency, startDate, manualProlong, agreementTypeCode) {
    const params = { amount, interestFrequency, startDate, agreementTypeCode }

    if (period) {
      params.period = period
    }

    if (periodCode) {
      params.periodCode = periodCode
    }

    if (manualProlong) {
      params.manualProlong = manualProlong
    }

    const url = getUrlWithQueryParams('/deposit/api/account/interest-parameter', params)

    return request(url, {})
  }

  async getDepositInterest (amount, period, yearlyInterest, startDate, periodCode = 'MONTHS') {
    let url = '/deposit/api/deposit/calculate-interest'
    url += `?amount=${amount}`
    url += `&period=${period}`
    url += `&interest=${yearlyInterest}`
    url += `&startDate=${startDate}`
    url += `&periodCode=${periodCode}`

    return request(url, {})
  }

  getDepositLimits (agreementTypeCode, accountTypeCode, periodCode) {
    const params = {}

    if (agreementTypeCode) {
      params.agreementTypeCode = agreementTypeCode
    }

    if (accountTypeCode) {
      params.accountTypeCode = accountTypeCode
    }

    if (periodCode) {
      params.periodCode = periodCode
    }

    const url = getUrlWithQueryParams('/deposit/api/deposit/all-interest-parameters', params)

    return request(url, {})
  }

  async getLookupValues (code) {
    return request(`/deposit/api/lookup/${code}`, {
      method: 'POST'
    })
  }

  completeAppCreation (flowData, constants) {
    const body = {
      agreementTypeCode: flowData.agreementTypeCode,
      iban: flowData.iban,
      amount: flowData.amount,
      interestPaymentFrequencyCode: flowData.paymentFrequencyCode,
      moneyOrigins: flowData.moneyOrigins,
      moneyOriginDescription: flowData.moneyOriginDescription,
      moneyOriginDocumentsUuid: flowData.moneyOriginDocumentsUuid,
      moneyOriginComment: flowData.moneyOriginComment,
      termsConfirmed: flowData.termsConfirmation,
      beneficiaryConfirmation: flowData.beneficiaryConfirmation
    }

    if (flowData.agreementTypeCode === constants.AGREEMENT_TYPE_CODES[DepositType.Term]) {
      body.automaticProlongation = flowData.automaticProlongation
      body.period = flowData.period
      body.periodCode = flowData.periodCode
      body.clearingNumber = flowData.clearingNumber
      body.principalAccount = flowData.principalAccount
    }

    if (flowData.deposit) {
      body.depositId = flowData.deposit.id
    }

    return request('/deposit/api/deposit/', {
      method: 'post',
      body
    })
  }

  getSignMethod (id) {
    return request(`/deposit/api/deposit/${id}/sign/get-sign-method`, {
      method: 'get',
      errHandlerOpts: {
        throwOnAllErrors: true
      }
    })
  }

  initSigningIdCard (id, signingRequestId, body = {}) {
    return request(`/deposit/api/deposit/${id}/sign/id-card?signingRequestId=${signingRequestId}`, {
      method: 'post',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  completeSigningIdCard (id, signingRequestId, body = {}) {
    return request(`/deposit/api/deposit/${id}/sign/id-card/complete?signingRequestId=${signingRequestId}`, {
      method: 'post',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  initSigningMobileId (id, signingRequestId) {
    return request(`/deposit/api/deposit/${id}/sign/mobile-id?signingRequestId=${signingRequestId}`, {
      method: 'put'
    }, ignoreErrorHandler)
  }

  completeSigningMobileId (id, signingRequestId) {
    return request(`/deposit/api/deposit/${id}/sign/mobile-id/complete?signingRequestId=${signingRequestId}`, {
      method: 'put',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  initSigningSmartId (id, signingRequestId) {
    return request(`/deposit/api/deposit/${id}/sign/smart-id?signingRequestId=${signingRequestId}`, {
      method: 'put'
    }, ignoreErrorHandler)
  }

  completeSigningSmartId (id, signingRequestId) {
    return request(`/deposit/api/deposit/${id}/sign/smart-id/complete?signingRequestId=${signingRequestId}`, {
      method: 'put',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  initSigningEvrotrust (id, signingRequestId, body = {}) {
    return request(`/deposit/api/deposit/${id}/sign/evrotrust?signingRequestId=${signingRequestId}`, {
      method: 'put',
      body
    }, ignoreErrorHandler)
  }

  completeSigningEvrotrust (id, signingRequestId) {
    return request(`/deposit/api/deposit/${id}/sign/evrotrust/complete?signingRequestId=${signingRequestId}`, {
      method: 'put'
    }, ignoreErrorHandler)
  }

  getValidatedAccounts () {
    return this.getAndCache('getValidatedAccounts', () => {
      return request('/deposit/api/customer/accounts', {
        errHandlerOpts: {
          handleOnlyKnownErrors: true
        }
      })
    }, { ttl: 60 * 1000 })
  }

  getSavingsDepoIbans () {
    return this.getAndCache('getSavingsDepositAccounts', () => {
      return request('/deposit/api/customer/savings-deposit-accounts', {
        errHandlerOpts: {
          handleOnlyKnownErrors: true
        }
      })
    }, { ttl: 60 * 1000 })
  }

  async getValidIban (depositId) {
    return request(`/deposit/api/deposit/${depositId}/verify-validation-status`, {})
  }

  searchTransactions (depositId, searchParams = {}) {
    return request(`/deposit/api/deposit/${depositId}/transactions/search`, {
      method: 'post',
      body: searchParams,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  searchTermDepositTransactions (searchParams = {}) {
    return request('/deposit/api/deposit/transactions', {
      method: 'post',
      body: searchParams,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  startSignicatSigning (depositId, signingRequestId) {
    return request(`/deposit/api/deposit/${depositId}/sign/signicat?signingRequestId=${signingRequestId}`, {
      method: 'PUT'
    })
  }

  startCustomerIdSigning (depositId, signingRequestId, retry) {
    return request(`/deposit/api/deposit/${depositId}/sign/customer-id?signingRequestId=${signingRequestId}`, {
      body: {
        retry
      },
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  completeCustomerIdSigning (depositId, signingRequestId, requestBody) {
    return request(`/deposit/api/deposit/${depositId}/sign/customer-id/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      body: requestBody,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  startBankIDSigning (depositId, signingRequestId) {
    return request(`/deposit/api/deposit/${depositId}/sign/bankid?signingRequestId=${signingRequestId}`, {
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      },
      method: 'PUT'
    })
  }

  completeBankIDSigning (depositId, signingRequestId) {
    return request(`/deposit/api/deposit/${depositId}/sign/bankid/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  startEparakstsSigning (depositId, signingRequestId, redirectUrl) {
    return request(`/deposit/api/deposit/${depositId}/sign/eparaksts?signingRequestId=${signingRequestId}&redirectUrl=${encodeURIComponent(redirectUrl)}`, {
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      },
      method: 'PUT'
    })
  }

  completeEparakstsSigning (depositId, signingRequestId) {
    return request(`/deposit/api/deposit/${depositId}/sign/eparaksts/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  getWithdrawalInfo (depositId) {
    return request(`/deposit/api/deposit/${depositId}/withdraw`, {})
  }

  initWithdraw (depositId, requestBody) {
    return request(`/deposit/api/deposit/${depositId}/sign/init/withdraw`, {
      method: 'post',
      body: requestBody,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  initClose (depositId, requestBody = {}) {
    return request(`/deposit/api/deposit/${depositId}/sign/init/close`, {
      method: 'post',
      body: requestBody,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }

  initContractChange (depositId, requestBody = {}) {
    return request(`/deposit/api/deposit/${depositId}/sign/init/contract-change`, {
      method: 'post',
      body: requestBody,
      errHandlerOpts: {
        throwOnAllErrors: true
      }
    })
  }

  downloadAccountStatement (depositId, body = {}, fileName) {
    return downloadFile(
      `/deposit/api/deposit/${depositId}/download-account-statement`,
      `${fileName}.pdf`,
      {
        method: 'post',
        body
      }
    )
  }

  uploadMoneyOriginDocuments (files) {
    const body = new FormData()

    files.forEach(f => { body.append('documents', f.file) })

    return request('/deposit/api/deposit/upload-money-origin-files', {
      body,
      method: 'post',
      contentType: `multipart/form-data; boundary=${body._boundary}`
    })
  }

  downloadMoneyOriginDocument (depositId, fileId, fileName) {
    return downloadFile(
      `/deposit/api/deposit/${depositId}/download-money-origin-file?fileId=${fileId}`,
      fileName
    )
  }

  getMoneyOriginDetails (id) {
    return request(`/deposit/api/deposit/${id}/money-origin-details`, {
      method: 'get'
    })
  }

  createMoneyOriginDetails (id, comment, files) {
    const formData = new FormData()

    files.forEach(f => {
      formData.append('documents', f.file)
    })

    formData.set('comment', comment)

    return request(`/deposit/api/deposit/${id}/money-origin-details`, {
      method: 'post',
      contentType: `multipart/form-data; boundary=${formData._boundary}`,
      body: formData
    })
  }

  getSavingDepoistReservations (accountId, currencyCode) {
    return request(`/deposit/api/deposit/${accountId}/savings-deposit-reservations/${currencyCode}`, {
      method: 'get'
    })
  }

  initReleaseReservedOutpayment (depositId, accountId, reservedOutPayment) {
    return request(`/deposit/api/deposit/${depositId}/sign/release-reserved-outpayment/${accountId}`, {
      method: 'post',
      body: reservedOutPayment,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }
}

export const api = new Api()

function ignoreErrorHandler () {
  return null
}
