<template>
  <div>
    <bb-application-contract-card
      v-bind="cardProps"
      class="m-b-15"
      @primaryButtonClick="handleClick(cardPrimaryButton)"
      @secondaryButtonClick="handleClick(cardProps.secondaryButton[$event].type)"
    />
    <transfer-details
      v-if="showTransferDetailsModal && transferDetails"
      @close="showTransferDetailsModal = !showTransferDetailsModal"
      :transferDetails="transferDetailsFiltered"
    />
  </div>
</template>

<script>
import FlowMixin from '../mixins/flowMixin'
import '@bigbank/interface-components/dist/svg/ui/chevron-right'
import '@bigbank/interface-components/dist/svg/client/sign'
import TransferDetails from './TransferDetails'
import { depositStates, depositButtons, depositIconsByState } from '../utils/depositStates'
import currencyDefinitions from '../utils/getCurrency'
import { DEMAND_DEPOSIT_DASHBOARD, getViewDepositTrackerAction } from '@/TrackingActions'
import dayjs from 'dayjs'
import { formatDate } from '@/plugins/dateFormatters'
import { formatInterest, formatMoneyWithCurrency } from '@/plugins/numformat'
import { DepositType, DepositRouteName } from '@deposits/const'
import { AccountRouteName } from '../../account/const'
import { useRootStore } from '../../../store/root'
import { mapState } from 'pinia'
import { useDepositStore } from '@deposits/store/depositStore'
import { invert } from 'lodash'

export default {
  name: 'deposit-card',
  mixins: [FlowMixin],
  components: {
    TransferDetails
  },
  data () {
    return {
      showTransferDetailsModal: false,
      buttonTypes: depositButtons,
      today: dayjs()
    }
  },
  mounted () {
    const trackState = (this.deposit.showProlongButton && this.state === depositStates.ACTIVE) ? depositStates.ACTIVE_PROLONG : this.state
    const depositId = (this.deposit && this.deposit.id) ? this.deposit.id.toString() : null

    if (depositId) {
      this.$tracker.action(getViewDepositTrackerAction(trackState, this.deposit.depositType), {}, { contractId: depositId })
    }
  },
  props: {
    deposit: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'isCompany', 'locale', 'isChannelSE']),
    ...mapState(useDepositStore, ['featureFlags']),
    internalState () {
      if (this.isWaitingActivation) {
        return 'WAITING_ACTIVATION'
      }

      return this.state
    },
    translations () {
      return {
        buttons: {
          [this.buttonTypes.FUNDING_OPTIONS]: this.$gettext('Funding options'),
          [this.buttonTypes.TRANSFER_DETAILS]: this.$gettext('Transfer Details'),
          [this.buttonTypes.SIGN_CONTRACT]: this.$gettext('Sign contract'),
          [this.buttonTypes.PROLONG_CONTRACT]: this.$gettext('Prolong Contract'),
          [this.buttonTypes.APPLY_AGAIN]: this.$gettext('Apply again'),
          [this.buttonTypes.UPLOAD_FILES]: this.$gettext('Money origin files'),
          contract_details: this.$gettext('Contract Details'),
          view_transactions: this.$gettext('View transactions')
        },
        sectionLabels: {
          depositAmount: this.$gettext('DEPOSIT AMOUNT'),
          interestRate: this.$gettext('INTEREST RATE'),
          depositPeriod: this.$gettext('DEPOSIT PERIOD'),
          accruedInterestRegular: this.$pgettext('term_deposit_product_page', 'Accrued interest'),
          accruedInterestCorporate: this.$pgettext('corporate_term_deposit_product_page', 'Accrued interest'),
          activationDateRegular: this.$pgettext('term_deposit_product_page', 'Activation date'),
          activationDateCorporate: this.$pgettext('corporate_term_deposit_product_page', 'Activation date')
        },
        rejectedSubtitle: this.$gettext('Unfortunately we are not able to offer you a deposit account at this moment.'),
        waitingSigningSubtitle: this.$gettext('Your contract is ready to sign!'),
        waitingSigningDescription: this.$gettext('Please review your deposit conditions and proceed with signing.'),
        proceedingSubtitle: this.$gettext('We are processing your application.'),
        proceedingDescription: this.$gettext('Please stand by.'),
        states: {
          REJECTED: this.$pgettext('state', 'Rejected'),
          ACTIVE: this.$pgettext('state', 'Active'),
          CLOSED: this.$pgettext('state', 'Closed'),
          WAITING_FUNDS: this.$pgettext('state', 'Waiting funds'),
          WAITING_ACTIVATION: this.$pgettext('state', 'Waiting for activation'),
          WAITING_SIGNING: this.$pgettext('state', 'Waiting signing'),
          TERMINATED: this.$pgettext('state', 'Terminated'),
          FORWARDED: this.$pgettext('state', 'Forwarded'),
          PROCEEDING: this.$pgettext('state', 'Proceeding'),
          UNKNOWN: this.$pgettext('state', 'Unknown')
        },
        payoutFrequencies: {
          END: this.$pgettext('payment_frequency', 'At the end'),
          YEAR: this.$pgettext('payment_frequency', 'Every year'),
          MONTH: this.$pgettext('payment_frequency', 'Every month')
        },
        prolong: {
          YES: this.$pgettext('prolong', 'Active'),
          NO: this.$pgettext('prolong', 'No')
        },
        statusLabel: {
          regular: {
            days: this.$pgettext('term_deposit_product_page', 'days'),
            contractEnds: this.$pgettext('term_deposit_product_page', 'contract ends')
          },
          corporate: {
            days: this.$pgettext('corporate_term_deposit_product_page', 'days'),
            contractEnds: this.$pgettext('corporate_term_deposit_product_page', 'contract ends')
          }
        }
      }
    },
    cardProps () {
      if (this.isWaitingActivation) {
        return this.waitingActivationCardProps
      } else if (this.isWaitingFunds) {
        return this.waitingFundsCardProps
      } else if (this.state === depositStates.REJECTED) {
        return this.rejectedCardProps
      } else if (this.isWaitingMoneyOriginFiles) {
        return this.waitingMoneyOriginCardProps
      } else if (this.isBeingReviewed) {
        return this.proceedingCardProps
      } else if (this.state === depositStates.WAITING_SIGNING) {
        return this.waitingSigningCardProps
      } else {
        return this.cardPropsWithSection
      }
    },
    cardSections () {
      const extraSection = this.isTerminatedOrClosed
        ? [{
            label: this.isCompany ? this.translations.sectionLabels.activationDateCorporate : this.translations.sectionLabels.activationDateRegular,
            content: formatDate(this.deposit.activationDate)
          }]
        : [{
            label: this.isCompany ? this.translations.sectionLabels.accruedInterestCorporate : this.translations.sectionLabels.accruedInterestRegular,
            content: this.accruedInterestFormated
          }]

      return [
        {
          label: this.translations.sectionLabels.depositAmount,
          content: this.depositAmount
        },
        {
          label: this.translations.sectionLabels.interestRate,
          content: formatInterest(this.deposit.interest, this.locale, true)
        },
        {
          label: this.translations.sectionLabels.depositPeriod,
          content: this.depositPeriodFormated
        },
        ...extraSection
      ]
    },
    defaultCardProps () {
      return {
        icon: this.headerIcon,
        amount: this.depositAmount || undefined,
        title: this.deposit.contractNumber,
        secondaryButton: this.getSecondaryButton()
      }
    },
    waitingMoneyOriginCardProps () {
      return {
        ...this.defaultCardProps,
        subtitle: this.translations.proceedingSubtitle,
        description: this.translations.proceedingDescription,
        primaryButton: this.getPrimaryButton()
      }
    },
    waitingFundsCardProps () {
      const showTransferDetails = this.isMobile && !this.showApplyAgainButton && !this.showFundingOptionsButton

      return {
        ...this.defaultCardProps,
        subtitle: this.noticeDetails.title,
        description: this.noticeDetails.description,
        primaryButton: this.getPrimaryButton()
      }
    },
    waitingActivationCardProps () {
      return {
        type: 'depo',
        status: 'inactive',
        title: this.deposit.contractNumber,
        secondaryTitle: this.formattedState,
        sections: this.cardSections,
        secondaryButton: this.getSecondaryButton()
      }
    },
    rejectedCardProps () {
      return {
        ...this.defaultCardProps,
        subtitle: this.translations.rejectedSubtitle
      }
    },
    proceedingCardProps () {
      return {
        ...this.defaultCardProps,
        subtitle: this.translations.proceedingSubtitle,
        description: this.translations.proceedingDescription
      }
    },
    waitingSigningCardProps () {
      const props = {
        ...this.defaultCardProps,
        subtitle: this.translations.waitingSigningSubtitle,
        description: this.translations.waitingSigningDescription,
        primaryButton: this.getPrimaryButton()
      }

      if (this.showStatus) {
        props.depoStatusText = this.statusLabel + ' ' + this.status
      }

      return props
    },
    cardPropsWithSection () {
      const props = {
        type: 'depo',
        title: this.deposit.contractNumber,
        secondaryTitle: this.statusLabel,
        timeRemaining: this.status,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton(),
        sections: this.cardSections,
        depoStatusText: ' ',
        progress: { value: this.progressValue, max: this.progressMax },
        flavorAgnosticProgress: true
      }

      if (this.isTerminatedOrClosed) {
        props.depoType = 'term'
        props.status = 'closed'
        props.secondaryTitle = this.formattedState + ':'
        props.depoStatusText = this.statusLabel + ' ' + this.status
        props.separator = true
        props.flavorAgnosticProgress = true
        props.progress = null
      }

      return props
    },
    cardPrimaryButton () {
      if (this.showSigningButton) {
        return this.buttonTypes.SIGN_CONTRACT
      } else if (this.isWaitingMoneyOriginFiles) {
        return this.buttonTypes.UPLOAD_FILES
      } else if (this.showTransferDetailsButton) {
        return this.buttonTypes.TRANSFER_DETAILS
      } else if (this.showFundingOptionsButton) {
        return this.buttonTypes.FUNDING_OPTIONS
      } else if (this.showProlongationButton) {
        return this.buttonTypes.PROLONG_CONTRACT
      } else if (this.showApplyAgainButton) {
        return this.buttonTypes.APPLY_AGAIN
      } else {
        return null
      }
    },
    depositAmount () {
      let amount = this.deposit.amount

      if (this.state === depositStates.ACTIVE) {
        amount = this.deposit.availableBalance
      }

      return formatMoneyWithCurrency(amount, this.currency, this.locale)
    },
    state () {
      return this.deposit.state
    },
    isWaitingMoneyOriginFiles () {
      return this.deposit.isWaitingMoneyOriginFiles
    },
    isWaitingFunds () {
      return this.state === depositStates.WAITING_FUNDS
    },
    isWaitingActivation () {
      return this.state === depositStates.WAITING_FUNDS &&
        this.transferDetails &&
        this.transferDetails.amount <= 0
    },
    isTerminated () {
      return this.state === depositStates.TERMINATED
    },
    isClosed () {
      return this.state === depositStates.CLOSED
    },
    isAnyClosedState () {
      return ['TERMINATED', 'CANCELED', 'REJECTED', 'CLOSED'].includes(this.state)
    },
    headerIcon () {
      if (this.state === depositStates.WAITING_FUNDS && this.isProlongation) {
        return false
      }

      const iconName = depositIconsByState[this.state] || false

      return { name: iconName }
    },
    formattedState () {
      return this.translations.states[this.internalState]
    },
    isProlongation () {
      return this.deposit.isInProlongationState
    },
    isBeingReviewed () {
      return [depositStates.PROCEEDING, depositStates.FORWARDED].includes(this.state)
    },
    isTerminatedOrClosed () {
      return this.isTerminated || this.isClosed
    },
    showWaitingForActivation () {
      return this.state === depositStates.WAITING_FUNDS &&
        ((this.transferDetails && this.transferDetails.amount <= 0) || !this.daysToActivation)
    },
    showStatus () {
      return !this.showWaitingForActivation && this.state !== depositStates.PROCEEDING
    },
    showTransferDetailsButton () {
      return this.isWaitingFunds &&
        (!this.featureFlags.enableTermDepositFundingOptions) &&
        !this.isMobile &&
        !this.showApplyAgainButton
    },
    showFundingOptionsButton () {
      return this.isWaitingFunds &&
        this.featureFlags.enableTermDepositFundingOptions &&
        (this.transferDetails && this.transferDetails.amount > 0) &&
        !this.showApplyAgainButton
    },
    showApplyAgainButton () {
      return this.isWaitingFunds && this.daysToActivate < 0
    },
    showSigningButton () {
      return this.state === depositStates.WAITING_SIGNING && !this.featureFlags.disableDepositSigning
    },
    showProlongationButton () {
      return !!this.deposit.showProlongButton
    },
    currency () {
      return currencyDefinitions(this.deposit.currencyCode)
    },
    daysToActivation () {
      if (!this.deposit.hasSourceContract || !this.deposit.sourceContractEndDate) {
        return null
      }
      return dayjs(this.deposit.sourceContractEndDate).diff(this.today, 'day')
    },
    isLessThanFourteenDays () {
      const diff = dayjs(this.deposit.endDate).diff(this.today, 'day')
      return diff < 14 && diff >= 0
    },
    status () {
      let date = this.deposit.endDate

      if (this.isProlongation && this.deposit.hasSourceContract && this.deposit.sourceContractEndDate) {
        date = this.deposit.sourceContractEndDate
      }

      if (this.isLessThanFourteenDays) {
        return `${dayjs(date).diff(this.today, 'day')} ${this.isCompany ? this.translations.statusLabel.corporate.days : this.translations.statusLabel.regular.days}`
      }

      return formatDate(date)
    },
    statusLabel () {
      if (this.isTerminatedOrClosed) {
        return this.$gettext('payout on')
      }

      if (this.isProlongation) {
        return this.$gettext('effective in') + ':'
      }

      return this.isCompany ? this.translations.statusLabel.company.contractEnds + ':' : this.translations.statusLabel.regular.contractEnds + ':'
    },
    progressValue () {
      const activationDate = dayjs(this.deposit.activationDate)
      return this.isTerminatedOrClosed ? 1 : (this.today.diff(activationDate, 'day') || 0)
    },
    progressMax () {
      const activationDate = dayjs(this.deposit.activationDate)
      const endDate = dayjs(this.deposit.endDate)
      return this.isTerminatedOrClosed ? 1 : (endDate.diff(activationDate, 'day') || 0)
    },
    daysToActivate () {
      if (!this.deposit || !this.deposit.lastActivationDate) {
        return null
      }

      const lastDayToActivate = dayjs(this.deposit.lastActivationDate)
      return lastDayToActivate.diff(this.today, 'day')
    },
    noticeType () {
      if (this.showApplyAgainButton) {
        return 'PAST_DUE'
      } else if (this.deposit.hasSourceContract) {
        return 'TRANSFER_SOON_PROLONG'
      } else {
        return 'TRANSFER_SOON'
      }
    },
    noticeDetails () {
      const translationType = this.isCompany ? 'corporate' : 'regular'

      return {
        TRANSFER_SOON: {
          title: this.$gettext('Deposit amount needs to be transferred within {days_left} days.').replace(/{days_left}/, this.daysToActivate),
          description: this.$gettext('In order to activate your deposit account please check the transfer details')
        },
        TRANSFER_SOON_PROLONG: {
          title: this.$pgettext('TERM_DEPOSIT_PROLONG', 'We are waiting for your funds!'),
          description: this.$pgettext('TERM_DEPOSIT_PROLONG', 'In order to activate your deposit account please check your account details.')
        },
        PAST_DUE: {
          title: this.$gettext('Deposit amount payment date has passed.'),
          description: this.$gettext('In order to open a deposit, please apply again.')
        }
      }[this.noticeType] || false
    },
    depositPeriodCode () {
      switch (this.deposit.periodCode.toLowerCase()) {
        case 'days':
          return this.$pgettext('period_code', 'd')
        case 'months':
          return this.$pgettext('period_code', 'mo')
        case 'years':
          return this.$pgettext('period_code', 'y')
        default:
          return ''
      }
    },
    depositPeriodFormated () {
      return this.deposit.period + ' ' + this.depositPeriodCode
    },
    accruedInterestFormated () {
      return formatMoneyWithCurrency(this.deposit.accruedInterest, this.currency, this.locale)
    },
    prolongStatus () {
      return this.translations.prolong[this.deposit.isAutomatedProlong ? 'YES' : 'NO']
    },
    transferDetails () {
      return this.deposit.transferDetails
    },
    transferDetailsFiltered () {
      return { ...this.transferDetails || {} }
    }
  },
  methods: {
    async showTransferDetails () {
      this.showTransferDetailsModal = true
    },
    showFundingOptions () {
      this.$router.push({
        name: DepositRouteName.FundingOptions,
        params: { depositId: this.deposit.id }
      })
    },
    getPrimaryButton () {
      if (this.cardPrimaryButton) {
        return {
          label: this.translations.buttons[this.cardPrimaryButton],
          inverted: [this.buttonTypes.TRANSFER_DETAILS, this.buttonTypes.APPLY_AGAIN].includes(this.cardPrimaryButton)
        }
      }

      return undefined
    },
    getSecondaryButton () {
      const isActiveOrClosed = this.state === depositStates.ACTIVE || this.isClosed

      return [
        { label: this.translations.buttons.contract_details, type: this.buttonTypes.CONTRACT_DETAILS },
        ...[isActiveOrClosed ? { label: this.translations.buttons.view_transactions, type: this.buttonTypes.VIEW_TRANSACTIONS } : []]
      ]
    },
    handleClick (buttonType) {
      switch (buttonType) {
        case this.buttonTypes.UPLOAD_FILES:
          this.$router.push({ name: DepositRouteName.MoneyOriginDocuments, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.CONTRACT_DETAILS:
          this.$router.push({ name: DepositRouteName.Contract, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.PROLONG_CONTRACT:
          this.$router.push({ name: DepositRouteName.Prolongation, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.SIGN_CONTRACT:
          this.$router.push({ name: DepositRouteName.Signing, params: { contractId: this.deposit.id } })
          break
        case this.buttonTypes.APPLY_AGAIN:
          this.startApplicationFlow(DepositType.Term)
          break
        case this.buttonTypes.TRANSFER_DETAILS:
          this.showTransferDetails()
          break
        case this.buttonTypes.FUNDING_OPTIONS:
          this.showFundingOptions()
          break
        case this.buttonTypes.VIEW_TRANSACTIONS:
          this.$router.push({ name: AccountRouteName.MyTransactions, query: { accountID: this.deposit.currentAccountId, contractID: this.deposit.id } })
          break
      }
    }
  }
}
</script>
