import { request, downloadFile } from '@/plugins/utils'
const cache = {}

async function getAndCache (key, getter, opts) {
  opts = Object.assign({ ttl: 5 * 60 * 1000 }, opts)
  const cacheValue = cache[key]

  if (!cacheValue || cacheValue.created < Date.now() - opts.ttl) {
    cache[key] = {
      created: Date.now(),
      value: await getter()
    }
  }

  return cache[key].value
}

export const api = {
  getContracts () {
    return request('/contract/api/contracts')
  },
  getLoanContractById (contractId) {
    return request(`/contract/api/contract/${contractId}/details`)
  },
  getApplications () {
    return request('/contract/api/applications', {
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getUpsell () {
    return request('/contract/api/customer/upsell', {
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getInvoiceDetails (contractId) {
    return request(`/contract/api/contract/${contractId}/invoiceDetails`)
  },
  getNextPaymentDetails (contractId) {
    return request(`/contract/api/contract/${contractId}/schedule`)
  },
  getPaymentInformation (contractId) {
    return request(`/contract/api/contract/${contractId}/payment-information`)
  },
  getReferenceNumber (contractId) {
    return request(`/contract/api/account/referenceNumber/${contractId}`)
  },
  getDeptProcess (contractId) {
    return request(`/contract/api/account/${contractId}/debtProcess/blocked`)
  },
  fetchRepaymentDetails (contractId) {
    return request(`/contract/api/contract/${contractId}/repaymentDetails`)
  },
  fetchProceedingDetails (contractId) {
    return request(`/contract/api/contract/${contractId}/proceeding/details`)
  },
  getInvoiceFile (contractId) {
    if (!contractId) {
      return Promise.resolve()
    }
    return request(`/contract/api/contract/${contractId}/file/invoice/metadata`).then(({ fileName, contentType }) => {
      return downloadFile(
        `/contract/api/contract/${contractId}/file/invoice/contents`,
        fileName,
        { contentType }
      )
    })
  },
  getFeatureFlags () {
    return request('/contract/api/features')
  },
  getTransactions (searchQueryParams = {}) {
    return request('/contract/api/transactions', {
      method: 'GET',
      params: searchQueryParams
    })
  },
  getTransactionsUsingContractId (contractId, searchQueryParams = {}) {
    return request(`/contract/api/transactions/${encodeURIComponent(contractId)}`, {
      method: 'GET',
      params: searchQueryParams
    })
  },
  getTransactionsSettings (items) {
    const queryString = items
      .reduce((acc, item) => `${acc}&items=${encodeURIComponent(item)}`, '')
      .substring(1)

    return request(`/contract/api/transactions/settings?${queryString}`, {
      method: 'GET'
    })
  },
  getContractLimitsAndPaymentsSummary (contractId) {
    return request(`/contract/api/summary/${encodeURIComponent(contractId)}/limits-and-payments`, {
      method: 'GET'
    })
  },
  getFullSchedule (contractId) {
    return request(`/contract/api/contract/${contractId}/full-schedule`, {
      method: 'GET'
    })
  },
  getInvoices (contractId) {
    return request(`/contract/api/contract/${contractId}/invoices`, {
      method: 'GET'
    })
  },
  getRepaymentDetails (contractId) {
    return this.fetchRepaymentDetails(contractId)
  },
  getProceedingDetails (contractId) {
    return getAndCache(`getProceedingDetails_${contractId}`, () => {
      return this.fetchProceedingDetails(contractId)
    }, { ttl: 60 * 1000 })
  },
  getContractDocuments (contractId) {
    return request(`/contract/api/contract/${contractId}/documents`, {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getContractChangeDocument (contractId) {
    return request(`/contract/api/contract/${contractId}/change/document`, {
      method: 'GET'
    })
  },
  initFileDownload (contractId, { fileId, fileName, contentType }) {
    return downloadFile(
      `/contract/api/contract/${encodeURIComponent(contractId)}/download/${encodeURIComponent(fileId)}`,
      fileName,
      { contentType }
    )
  },
  initSigning (contractId) {
    return request(`/contract/api/contract/${contractId}/sign/init`, {
      method: 'PUT'
    })
  },
  initTransfer (contractId, data) {
    return request(`/contract/api/contract/${contractId}/sign/init/transfer`, {
      method: 'PUT',
      body: data
    })
  },
  startSignicatSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/signicat?signingRequestId=${signingRequestId}`, {
      method: 'PUT'
    })
  },
  continueSignicatSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/signicat/continue?signingRequestId=${signingRequestId}`, {
      method: 'PUT'
    })
  },
  getValidatedAccounts () {
    return getAndCache('getValidatedAccounts', () => {
      return request('/contract/api/customer/accounts', {
        errHandlerOpts: {
          handleOnlyKnownErrors: true
        }
      })
    }, { ttl: 60 * 1000 })
  },
  startBankIDSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/bankid?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeBankIDSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/bankid/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  startSmartIdSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/smart-id?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeSmartIdSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/smart-id/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  startEparakstsSigning (contractId, signingRequestId, redirectUrl) {
    return request(`/contract/api/contract/${contractId}/sign/eparaksts?signingRequestId=${signingRequestId}&redirectUrl=${encodeURIComponent(redirectUrl)}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeEparakstsSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/eparaksts/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  startMobileIdSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/mobile-id?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeMobileIdSigning (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/sign/mobile-id/complete?signingRequestId=${signingRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  startIdCardSigning (contractId, signingRequestId, body = {}) {
    return request(`/contract/api/contract/${contractId}/sign/id-card?signingRequestId=${signingRequestId}`, {
      method: 'POST',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeIdCardSigning (contractId, signingRequestId, signatureBase64) {
    return request(`/contract/api/contract/${contractId}/sign/id-card/complete?signingRequestId=${signingRequestId}`, {
      method: 'POST',
      body: { signatureBase64 },
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getPromiseToPay () {
    return request('/contract/api/account/promise-to-pay', {
      method: 'GET'
    })
  },
  getAvailableMonthlyLimit () {
    return request('/contract/api/credit-cards/available-monthly-limit', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getPromiseToPayPayments (ptpId) {
    return request(`/contract/api/account/promise-to-pay/${ptpId}/payments`, {
      method: 'GET'
    })
  },
  getProlongationInfo (contractId) {
    return request(`/contract/api/contract/${contractId}/prolongation`, {
      method: 'GET'
    })
  },
  updateProlongation (contractId, data) {
    return request(`/contract/api/contract/${contractId}/prolongation`, {
      method: 'PUT',
      body: data
    })
  },
  getSigningStatus (contractId, signingRequestId) {
    return request(`/contract/api/contract/${contractId}/signing/status?signingRequestId=${signingRequestId}`, {
      method: 'GET'
    })
  },
  createManageLoanRequest (contractId, requestType, requestBody) {
    return request(`/contract/api/contract/${contractId}/manage/${requestType}`, {
      method: 'POST',
      body: requestBody
    })
  },
  getCreditCardsByContract (contractId) {
    return request(`/contract/api/credit-cards/find-by-contract-id/${encodeURIComponent(contractId)}/all`)
  },
  getCreditCardByCardId (cardId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/card`)
  },
  getCreditCardInfoByCardId (cardId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/info`)
  },
  initCreditCardSigningRequestByAction (signingAction, contractId, cardId, body = {}) {
    return request(`/contract/api/contract/${encodeURIComponent(contractId)}/sign/init/credit-card/${encodeURIComponent(cardId)}/${encodeURIComponent(signingAction)}`, {
      method: 'PUT',
      body
    })
  },
  getCreditCardSigningStatusByAction (signingAction, cardId, signingRequestId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/${encodeURIComponent(signingAction)}/status/${encodeURIComponent(signingRequestId)}`)
  },
  blockCreditCard (cardId, blockRequest) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/block`, {
      method: 'PUT',
      body: blockRequest
    })
  },
  unblockCreditCard (cardId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/unblock`, { method: 'PUT' })
  },
  getCreditCardSecrets (cardId, signedFileId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/secrets/${encodeURIComponent(signedFileId)}`)
  },
  generateCreditCardContract (cardId, contractId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/contract/${encodeURIComponent(contractId)}/generate`, { method: 'POST' })
  },
  downloadCreditCardContract (contractId, fileId) {
    return downloadFile(
      `/contract/api/credit-cards/contract/${encodeURIComponent(contractId)}/download/${encodeURIComponent(fileId)}`,
      'contract.pdf',
      { contentType: 'application/pdf' }
    )
  },
  orderCreditCard (contractId, cardType) {
    return request(`/contract/api/credit-cards/contract/${encodeURIComponent(contractId)}/order/${encodeURIComponent(cardType)}`, {
      method: 'POST',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  initCreditCardContractDownload (cardId, { fileName, contentType }) {
    return downloadFile(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/contract`, fileName, { contentType })
  },
  getCreditCardDocuments (cardId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/documents`)
  },
  sendInquiry (contractId, { periodStartDate, periodEndDate, accountNumber }) {
    return request(`/contract/api/credit-cards/contract/${encodeURIComponent(contractId)}/send-inquiry`, {
      method: 'POST',
      body: {
        periodStartDate,
        periodEndDate,
        accountNumber
      },
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getCreditCardLimitsConfiguration (cardId) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/limits`)
  },
  initCreditCardLimitUpdates (cardId, limits) {
    return request(`/contract/api/credit-cards/${encodeURIComponent(cardId)}/limits`, {
      method: 'PUT',
      body: limits
    })
  },
  downloadCreditCardAccountStatement (contractId, searchBody, fileType) {
    return downloadFile(
      `/contract/api/credit-cards/contract/${encodeURIComponent(contractId)}/download-account-statement/${encodeURIComponent(fileType.toLowerCase())}`,
      `statement.${fileType.toLowerCase()}`,
      {
        method: 'POST',
        body: searchBody
      }
    )
  },
  getCreditCardPricing () {
    return request('/contract/api/credit-cards/pricing')
  }
}

export default api
