<template>
  <div>
    <bb-card size="wide" class="card">
      <div class="card__container">
        <bb-credit-card
          v-bind="creditCardProps"
          @click="onCreditCardFooterButtonClicked"
        />
        <div class="card__container__status">
          <div class="card__container__status__title">{{translations.cardDetails}}</div>
          <bb-list-group>
            <bb-list-item
              v-for="item in detailItems"
              :key="item.key"
              :titleUppercase="false"
              :noBottomBorder="true"
              :noTopBorder="true"
              :title="item.title"
              condensed
            >
              <div
                class="card__container__status__item nm-y-5"
                :class="{ 'yellow' : item.key === 'closingDate' }"
              >
                <info-box-copy-button v-if="item.key === 'iban'" size="sm" :item="item.value" class="m-r-5" />
                <bb-icon
                  v-if="item.key === 'status'"
                  name="ui-circle-medium"
                  :fill="cardStatusColor"
                  size="24"
                  class="nm-y-5"
                />
                <div
                  v-if="item.key === 'closingDate'"
                  class="d-flex"
                  @mouseover="closingDateTooltipVisible = true"
                  @mouseleave="closingDateTooltipVisible = false"
                >
                  <bb-button
                    @click="closingDateTooltipVisible = true"
                    no-background
                    no-paddings
                    size="sm"
                    icon-after="ui-information-circle-outline"
                    icon-after-color="gray"
                    class="status-button"
                  >
                    <span class="f-color-gray f-gotham-bold" :name="item.name">
                      {{ item.value }}
                    </span>
                  </bb-button>
                  <bb-tooltip
                    v-if="closingDateTooltipVisible"
                    bubble
                    @hide="closingDateTooltipVisible = false"
                    :popperOpts="{ placement: isMobile ? 'bottom' : 'right' }"
                  >
                    {{ translations.closingDateTooltip[card.status] }}
                  </bb-tooltip>
                </div>
                <span v-else :data-testid="`${item.name}-${card.id}`" :data-value="item.dataValue">{{ item.value }}</span>
              </div>
            </bb-list-item>
          </bb-list-group>
        </div>
      </div>
      <setting-buttons v-if="!isMobile && isSettingsButtonsVisible" class="m-t-20" :card="card" :minimal="true" v-on="listeners" />
    </bb-card>
    <setting-buttons v-if="isMobile && isSettingsButtonsVisible" class="m-t-10" :card="card" v-on="listeners" />
    <limits v-if="isShowLimits" class="m-t-10" :card="card" v-on="listeners" />
    <component
      v-if="modalComponent"
      :is="modalComponent"
      v-bind="modalComponentProps"
      @onModalClosed="onModalClosed"/>
  </div>
</template>

<script>
import { formatToCreditCardExpiryDate, formatDate } from '@/plugins/dateFormatters'
import InfoBoxCopyButton from '@deposits/components/InfoBoxCopyButton.vue'
import SettingButtons from './SettingButtons.vue'
import Limits from './CardLimits.vue'
import { BbCreditCardStatus } from './credit-card.const'
import { CardStatus, CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { BbCreditCard } from '@bigbank/interface-components'
import CreditCardActivationModal from './modals/CreditCardActivationModal'
import CreditCardBlockModal from './modals/CreditCardBlockModal'
import CreditCardLimitsModal from './modals/CreditCardLimitsModal'
import CreditCardUnblockModal from './modals/CreditCardUnblockModal'
import CardTypeTranslationMixin from './mixins/CardTypeTranslationMixin'
import { CreditCardSigningAction } from '@bigbank/dc-common/enums/credit-card.enums'
import CreditCardSigningMixin from '@credit-card/components/mixins/CreditCardSigningMixin'
import CreditCardsSecretsModal from '@credit-card/components/modals/CreditCardSecretsModal.vue'
import { CREDIT_CARD } from '@/const'
import { useRootStore } from '../../../store/root'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { useCreditCardStore } from '@/store/modules/credit-card'
import CreditCardHolderBlockModal from '@credit-card/components/modals/CreditCardHolderBlockModal.vue'

export default {
  components: {
    InfoBoxCopyButton,
    SettingButtons,
    Limits,
    BbCreditCard,
    CreditCardActivationModal,
    CreditCardLimitsModal,
    CreditCardBlockModal,
    CreditCardUnblockModal,
    CreditCardsSecretsModal
  },
  mixins: [CardTypeTranslationMixin, CreditCardSigningMixin],
  props: {
    card: {
      required: true
    }
  },
  data () {
    return {
      isModalVisible: false,
      modalComponent: null,
      closingDateTooltipVisible: false,
      secretsModalType: null
    }
  },
  methods: {
    setModalComponent (component) { this.modalComponent = component },
    toggleModalVisibility (flag) { this.isModalVisible = flag },
    setSecretsModalType (type) { this.secretsModalType = type },
    onCreditCardFooterButtonClicked () {
      if (this.isUnblockingButtonShown) {
        this.showUnblockModal()
      } else if (this.isActivationButtonShown) {
        this.showActivationModal()
      }
    },
    showActivationModal () {
      if (this.card?.isCardActivationTemporaryBlocked) {
        this.setModalComponent(CreditCardHolderBlockModal)
      } else {
        this.setModalComponent(CreditCardActivationModal)
      }
      this.toggleModalVisibility(true)
    },
    showBlockModal () {
      this.setModalComponent(CreditCardBlockModal)
      this.toggleModalVisibility(true)
    },
    showUnblockModal () {
      this.setModalComponent(CreditCardUnblockModal)
      this.toggleModalVisibility(true)
    },
    showLimitsModal () {
      this.setModalComponent(CreditCardLimitsModal)
      this.toggleModalVisibility(true)
    },
    showPinModal () {
      this.setSecretsModalType(CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PIN)
      this.setModalComponent(CreditCardsSecretsModal)
      this.toggleModalVisibility(true)
    },
    showPanAndCvvModal () {
      this.setSecretsModalType(CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV)
      this.setModalComponent(CreditCardsSecretsModal)
      this.toggleModalVisibility(true)
    },
    onModalClosed () {
      this.toggleModalVisibility(false)
      this.setModalComponent(null)
      this.setSecretsModalType(null)
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'featureFlags']),
    ...mapState(useLoanStore, ['contractAdditionalDetails']),
    ...mapState(useCreditCardStore, ['areCreditCardButtonsEnabled']),
    isSettingsButtonsVisible () {
      const hasActiveBailiffProceeding = this.contractAdditionalDetails?.hasActiveBailiffProceeding

      return [CardStatus.ACTIVE, CardStatus.BLOCKED].includes(this.card.status) && !hasActiveBailiffProceeding
    },
    isActivationButtonShown () {
      return [CardStatus.CREATED, CardStatus.NEW, CardStatus.CLOSED].includes(this.card.status)
    },
    isCardVirtual () {
      return [CardType.VIRTUAL].includes(this.card.type)
    },
    isUnblockingButtonShown () {
      return [CardStatus.BLOCKED].includes(this.card.status)
    },
    isShowLimits () {
      return this.card && [CardStatus.ACTIVE, CardStatus.BLOCKED].includes(this.card.status) && this.card.limits && this.card.limits.length > 0
    },
    last4PANDigits () {
      return this.card.maskedPan.replace(/\*/g, '')
    },
    translations () {
      return {
        cardDetails: this.$pgettext('credit_card_card_view', 'Card details'),
        status: this.$pgettext('credit_card_card_view', 'Status'),
        cardType: this.$pgettext('credit_card_card_view', 'Card type'),
        virtualCardLabel: this.$pgettext('credit_card_card_view', 'Virtual card'),
        IBAN: this.$pgettext('credit_card_card_view', 'IBAN'),
        validToDate: this.$pgettext('credit_card_card_view', 'Expiry date'),
        closingDate: this.$pgettext('credit_card_card_view', 'Closing date'),
        closingDateTooltip: {
          [CardStatus.BLOCKED]: this.$pgettext('credit_card_card_view', 'The blocked card will be closed after 14 days'),
          [CardStatus.CLOSED]: this.$pgettext('credit_card_card_view', 'The closed card will be shown here for 30 days after it has been closed')
        },
        cardStatus: {
          ACTIVE: this.$pgettext('CREDIT_CARD_STATUS', 'Active'),
          BLOCKED: this.$pgettext('CREDIT_CARD_STATUS', 'Blocked'),
          CLOSED: this.$pgettext('CREDIT_CARD_STATUS', 'Closed'),
          CREATED: this.$pgettext('CREDIT_CARD_STATUS', 'Not active'),
          NEW: this.$pgettext('CREDIT_CARD_STATUS', 'New'),
          bailiffProceeding: this.$pgettext('CREDIT_CARD_STATUS_BAILIFF_PROCEEDING', 'Blocked')
        },
        cardAction: {
          activate: this.$pgettext('CREDIT_CARD_ACTION', 'Activate'),
          unblock: this.$pgettext('CREDIT_CARD_ACTION', 'Unblock')
        }
      }
    },
    modalComponentProps () {
      if (this.modalComponent === CreditCardsSecretsModal) {
        return {
          type: this.secretsModalType,
          cardId: this.card.id,
          visible: this.isModalVisible,
          cardType: this.card.type,
          signingWay: CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.DEFAULT
        }
      }
      return {
        cardId: this.card.id,
        cardType: this.card.type,
        visible: this.isModalVisible,
        maskedPan: this.card.maskedPan,
        card: this.card
      }
    },
    creditCardProps () {
      const hasActiveBailiffProceeding = this.contractAdditionalDetails?.hasActiveBailiffProceeding

      return {
        status: (() => {
          if (hasActiveBailiffProceeding) {
            return BbCreditCardStatus.BLOCKED
          }

          switch (this.card.status) {
            case CardStatus.ACTIVE: return BbCreditCardStatus.ACTIVATED
            case CardStatus.BLOCKED: return BbCreditCardStatus.BLOCKED
            case CardStatus.CLOSED: return BbCreditCardStatus.CLOSED
            case CardStatus.CREATED:
              return BbCreditCardStatus.DISABLED
            default: return BbCreditCardStatus.EMPTY
          }
        })(),
        statusLabel: (() => {
          if (hasActiveBailiffProceeding) {
            return this.translations.cardStatus.bailiffProceeding
          }

          if (this.card.status === CardStatus.BLOCKED) {
            return this.translations.cardStatus.BLOCKED
          } else if (this.card.status === CardStatus.CLOSED) {
            return this.translations.cardStatus.CLOSED
          }
        })(),
        buttonLabel: (() => {
          if (this.isActivationButtonShown) {
            return this.translations.cardAction.activate
          } else if (this.isUnblockingButtonShown || hasActiveBailiffProceeding) {
            return this.translations.cardAction.unblock
          }

          return ''
        })(),
        buttonProps: (() => {
          return {
            disabled: !this.areCreditCardButtonsEnabled || hasActiveBailiffProceeding,
            name: this.isActivationButtonShown ? 'activate' : 'unblock'
          }
        })(),
        virtualLabel: this.isCardVirtual ? this.translations.virtualCardLabel : '',
        type: this.isCardVirtual ? 'virtual' : 'physical',
        digits: this.last4PANDigits
      }
    },
    detailItems () {
      return [
        {
          key: 'status',
          title: this.translations.status,
          value: this.translations.cardStatus[this.card.status] || this.card.status,
          dataValue: this.card.status,
          name: 'cardStatus'
        },
        {
          key: 'cardType',
          title: this.translations.cardType,
          value: this.cardTypeLabel(this.card),
          name: 'cardType'
        },
        {
          key: 'iban',
          title: this.translations.IBAN,
          value: this.card.iban,
          name: 'cardIban'
        },
        {
          key: 'validToDate',
          title: this.translations.validToDate,
          value: formatToCreditCardExpiryDate(this.card.validToDate),
          name: 'cardValidToDate'
        },
        ...this.shouldShowCardClosingDate
          ? [
              {
                key: 'closingDate',
                title: this.translations.closingDate,
                value: formatDate(this.card.closingDate),
                name: 'cardClosingDate'
              }
            ]
          : []
      ]
    },
    cardStatusColor () {
      if ([CardStatus.ACTIVE].includes(this.card.status)) {
        return 'mint'
      } else if ([CardStatus.CREATED].includes(this.card.status)) {
        return 'red'
      } else if ([CardStatus.BLOCKED, CardStatus.CLOSED].includes(this.card.status)) {
        return 'yellow'
      } else {
        return 'gray-50'
      }
    },
    listeners () {
      return {
        onBlockCardClick: this.showBlockModal,
        onUnblockCardClick: this.showUnblockModal,
        onEditCardLimitsClick: this.showLimitsModal,
        onShowPinClick: this.showPinModal,
        onShowPanAndCvvClick: this.showPanAndCvvModal
      }
    },
    shouldShowCardClosingDate () {
      return [CardStatus.BLOCKED, CardStatus.CLOSED].includes(this.card.status) && this.featureFlags.enableCreditCardClosingDate
    }
  },
  mounted () {
    if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.Activate)) {
      this.showActivationModal()
    } else if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.UpdateLimits)) {
      this.showLimitsModal()
    } else if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.Unblock)) {
      this.showUnblockModal()
    } else if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.ViewPin)) {
      this.showPinModal()
    } else if (this.hasReturnedAfterSigningAction(CreditCardSigningAction.ViewCvvAndPan)) {
      this.showPanAndCvvModal()
    }
  }
}
</script>

<style lang="scss">
.card {
  &.bb-card {
    width: 100%;
    max-width: 100%;

    @media (max-width: $breakpoint-sm) {
      padding: 18px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bb-credit-card {
      margin-right: 43px;

      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    &__status {
      width: 50%;
      color: $gray;

      &__title {
        font-family: $gotham-medium;
        font-size: $default-font-size;
      }

      .bb-list-item__title,
      .bb-list-item__default-slot {
        color: $gray;
      }
    }

    @media (max-width: $mobile-view-breaking-point) {
      flex-direction: column;
      align-items: stretch;

      &__credit_card {
        width: 100%;
        padding-right: 0;
      }

      &__status {
        width: 100%;
        margin-top: 20px;
      }

      .bb-list-item__default-slot {
        font-size: $font-size-smallest;
      }
    }

    .card__container__status__item {
      position: relative;
      display: flex;
      align-items: center;

      &.yellow {
        height: 1.875rem;
        width: 150px;
        align-items: center;
        align-self: flex-start;
        padding: 0 1.25rem;
        background: $yellow;
        border-radius: 0.375rem;
        color: $gray;

        @media (max-width: $breakpoint-sm) {
          width: 140px;
        }
      }

      button:hover {
        background-color: unset !important;
      }
    }
  }
}
</style>
