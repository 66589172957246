<template>
  <layout-with-menu :loading="loading">
    <div class="credit-card">
      <hero-header v-bind="heroHeaderProps"/>
      <div class="credit-card__content">
        <div class="credit-card__content__cards" :class="{ empty: !hasContract && isApplicationsShown }">
          <div v-if="hasContract">
            <bb-card-button-group :buttons="cardButtons" @click="cardButtonClick" class="credit_card__content__buttons" />
          </div>
          <template v-if="isCardsListShown">
            <div class="credit-card__content__heading">{{ translations.my_credit_cards }}</div>
            <cards-list :cards="cards" />
          </template>
          <credit-card-applications v-if="isApplicationsShown" />
          <template v-else-if="!hasContract">
            <div class="credit-card__content__heading-empty-state">{{ translations.my_credit_cards }}</div>
            <empty-state-page />
          </template>
        </div>
        <order-virtual-credit-card-block v-if="!isApplicationsShown && isAllowedOrderVirtualCard"/>
        <credit-card-transactions-list
          v-if="isCardTransactionsListShown"
          :contractId="contract && contract.id"
          :contractNumber="contract && contract.contractNumber"
          :contractCurrencyCode="contract && contract.currencyCode"
        />
      </div>
    </div>
    <router-view />
  </layout-with-menu>
</template>

<script>
import HeroHeader from '@loan/components/HeroHeader'
import { mapState, mapActions } from 'pinia'
import { CREDIT_CARD_DASHBOARD } from '@/TrackingActions'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import CardsList from './CardsList.vue'
import EmptyStatePage from './EmptyStatePage.vue'
import OrderVirtualCreditCardBlock from '@credit-card/components/OrderVirtualCreditCardBlock'
import { CardStatus, CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import isUndefined from 'lodash/isUndefined'
import '@bigbank/interface-components/dist/svg/ui/file-document-outline'
import CreditCardTransactionsList from '@credit-card/components/CreditCardTransactionsList.vue'
import DebtNotificationMixin from '../../../mixins/debtNotificationMixin'
import CreditCardApplications from '@credit-card/components/CreditCardApplications.vue'
import { useRootStore } from '@/store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { useCreditCardStore } from '@/store/modules/credit-card'
import { translationPicker } from '@/plugins/translations'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  name: 'credit-card-view',
  components: {
    CreditCardTransactionsList,
    HeroHeader,
    CardsList,
    EmptyStatePage,
    OrderVirtualCreditCardBlock,
    CreditCardApplications
  },
  watch: {
    async contract (contract) {
      if (contract) {
        const requests = [
          this.getCreditCards(contract.id),
          this.getContractAdditionalDetails(contract.id)
        ]
        if (this.isCardTransactionsListShown) {
          requests.push(this.initCreditCardTransactions(contract.id))
        }

        await Promise.all(requests)
      } else {
        this.resetCreditCards()
      }
    }
  },
  mixins: [DebtNotificationMixin],
  computed: {
    ...mapState(useRootStore, [
      'language',
      'isMobile',
      'locale',
      'currencySymbol',
      'channelConfig'
    ]),
    ...mapState(useLoanStore, [
      'loading',
      'featureFlags'
    ]),
    ...mapState(useCreditCardStore, [
      'creditCards',
      'creditCardHeroHeaderData',
      'creditCardContract'
    ]),
    translations () {
      return {
        my_credit_cards: this.$pgettext('credit_card_card_view', 'My credit cards')
      }
    },
    cards () {
      return this.creditCards
    },
    contract () {
      return this.creditCardContract
    },
    hasContract () {
      return !!this.contract
    },
    contracts () {
      return this.hasContract ? [this.contract] : []
    },
    isCardsListShown () {
      return this.cards && this.cards.length > 0
    },
    isAllowedOrderVirtualCard () {
      return isUndefined(this.cards.find((card) => {
        return card.type === CardType.VIRTUAL &&
          [
            CardStatus.ACTIVE,
            CardStatus.CREATED,
            CardStatus.BLOCKED
          ].includes(card.status)
      })) && this.featureFlags.enableOrderVirtualCreditCard
    },
    isApplicationsShown () {
      return this.getApplicationsByType(InternalLoanType.CREDIT_CARD).active.length > 0 &&
        this.featureFlags.enableShowCreditCardApplicationUnderCreditCardView
    },
    isCardTransactionsListShown () {
      return (this.contract || this.isApplicationsShown) && this.featureFlags.enableCreditCardTransactionsOverview
    },
    currency () {
      return this.contract?.currency || this.currencySymbol
    },
    cardButtons () {
      return [
        {
          id: 'pay-back',
          label: this.$pgettext('credit_card_button', 'Pay Back'),
          icon: 'ui-arrow-top-right',
          to: '/my-credit-card/pay-back',
          disabled: !this.showPayBack,
          visible: true
        },
        {
          id: 'contract-details',
          label: this.$pgettext('credit_card_button', 'Contract'),
          icon: 'ui-file-document-outline',
          to: {
            name: 'credit-card-contract-details',
            params: {
              contractId: this.contract?.id
            }
          },
          disabled: !this.showContract,
          visible: this.featureFlags.enableCreditCardContractDetails
        },
        {
          id: 'partner-offers',
          label: this.$pgettext('credit_card_button', 'Partner offers'),
          icon: 'ui-ticket-percent-outline',
          target: '_blank',
          to: translationPicker(this.channelConfig.creditCardPartnerOffersUrl, this.language),
          visible: this.featureFlags.showCreditCardPartnerOffers
        }
      ].filter(button => button.visible)
    },
    showPayBack () {
      return this.hasContract
    },
    showContract () {
      return this.hasContract
    },
    heroHeaderProps () {
      return {
        contracts: this.contracts,
        currency: this.currency,
        flavour: 'credit-card',
        top: {
          value: this.creditCardHeroHeaderData.availableLimitAmount,
          label: this.$pgettext('credit_card_hero_header', 'Available limit'),
          name: 'hero-header-available-limit-top'
        },
        leftSide: {
          value: formatMoneyWithCurrency(this.creditCardHeroHeaderData.availableLimitAmount, this.currency, this.locale, {
            nullAs: '-'
          }),
          label: this.$pgettext('credit_card_hero_header', 'Available limit'),
          name: 'hero-header-available-limit'
        },
        rightSide: {
          value: formatMoneyWithCurrency(this.creditCardHeroHeaderData.reservations, this.currency, this.locale, {
            nullAs: '-'
          }),
          label: this.$pgettext('credit_card_hero_header', 'Reservations'),
          name: 'hero-header-reservations'
        }
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'init',
      'getApplicationsByType'
    ]),
    ...mapActions(useCreditCardStore, [
      'initCreditCardTransactions',
      'getCreditCards',
      'resetCreditCards',
      'getContractAdditionalDetails'
    ]),
    trackViewActions () {
      if (!this.hasContract) {
        this.$tracker.action(CREDIT_CARD_DASHBOARD.EMPTY_VIEW)
      }
    },
    cardButtonClick (event) {
      switch (event) {
        case 'pay-back':
          this.$tracker.action(
            CREDIT_CARD_DASHBOARD.CLICK_PAY_BACK,
            {}, { contractId: this.contract.id.toString() }
          )
          break
      }
    }
  },
  async mounted () {
    await this.init()
    const hasContractOrApplications = this.hasContract || this.isApplicationsShown
    if (this.featureFlags.enableCreditCardSilentLaunchCrossSell && !hasContractOrApplications) {
      this.$router.push('/')
      return
    }

    this.trackViewActions()
  }
}
</script>

<style lang="scss" scoped>
.credit-card {
  &__content {
    margin: auto;
    width: 90%;
    max-width: 850px;

    &__heading,
    &__heading-empty-state {
      font-family: $gotham-medium;
      font-size: $h4-size;
      color: $navy;
      margin-bottom: 20px;

      @media (min-width: $desktop-view-breaking-point) {
        font-size: $h3-size;
      }
    }

    &__heading {
      margin-top: 44px;
    }

    &__heading-empty-state {
      margin-top: 86px;

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 89px;
      }
    }

    &__menu {
      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 20px;
        margin-bottom: 35px;
      }
    }

    &__buttons {
      margin-bottom: 10px;

      @media (min-width: ($desktop-view-breaking-point + 1)) {
        margin-bottom: 10px;
      }
    }

    &__cards {
      margin-top: -45px;

      &.empty {
        @media (min-width: $desktop-view-breaking-point) {
          margin-top: 44px;
        }
      }
    }

    &__card {
      margin-top: 15px;
    }
  }
}
</style>
