import { defineStore } from 'pinia'
import { getEmptyAccountStatementData } from '../../../components/transactions/transactions.helpers'
import { TransactionSettingsItem } from '@bigbank/dc-common/enums/loan.enums'
import {
  TransactionAdvancedSearchOption,
  TransactionsPeriodFilter
} from '@bigbank/dc-common/enums/credit-card.enums'
import api from '../api'

export const useAccountStatementStore = defineStore('accountStatement', {
  state: () => ({
    accountsForStatement: [],
    accountStatement: getEmptyAccountStatementData('account'),
    accountStatementSearchRequest: {
      accountId: null,
      cardId: null
    },
    accountStatementTransactionSettings: {
      [TransactionSettingsItem.FilteringMaxPeriodInYears]: 5,
      [TransactionSettingsItem.FilteringPeriods]: {},
      [TransactionSettingsItem.FilteringAdvancedSearchOptions]: TransactionAdvancedSearchOption,
      [TransactionSettingsItem.FilteringPeriodByDefault]: TransactionsPeriodFilter.CurrentYear,
      [TransactionSettingsItem.FilteringAdvancedSearchOptionByDefault]: TransactionAdvancedSearchOption.AnyFieldContains,
      [TransactionSettingsItem.FilteringDatePickerLimits]: {
        min: null,
        max: null
      }
    },
    isStatementOfFeesCheckboxEnabled: false
  }),
  actions: {
    resetAccountStatementData () {
      this.accountStatementSearchRequest = {
        accountId: null,
        cardId: null
      }
      this.accountStatement = getEmptyAccountStatementData('account')
    },
    updateAccountStatementSearchRequest (request) {
      this.accountStatementSearchRequest = request
    },
    async fetchAccountStatementData (append = false) {
      const transactions = await api.getAccountStatement(this.accountStatementSearchRequest)
      if (append) {
        this.accountStatement.transactions.push(...transactions.transactions)
      } else {
        this.accountStatement = transactions
      }
    },
    async fetchAccountStatementSettings () {
      this.accountStatementTransactionSettings = await api.getAccountStatementSettings()
    },
    async fetchAccountsForStatement () {
      this.accountsForStatement = await api.getAccountsForStatement()
    }
  }
})
