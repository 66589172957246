<template>
  <div class="loan_contract_details">
    <div v-if="contract" class="loan_contract_details__wrapper">
      <div class="contract_details__content" v-if="contractNumber">
        <div class="_header">
          <div class="_title">
            <h3 class="contract_details__title">
              {{translations.contractTitle}}:
              {{contractNumber}}
            </h3>
          </div>
          <div class="_button hide-mobile">
            <bb-button
              v-if="showInvoiceDetailsButton"
              class="card-links--invoice-details"
              color="blue"
              inverted
              @click="clickInvoiceDetails()">
              {{translations.invoiceDetailsText}}
            </bb-button>
          </div>
        </div>
        <div class="invoice-details-button-mobile">
          <bb-button
            v-if="showInvoiceDetailsButton"
            class="card-links--invoice-details"
            color="blue"
            display="block"
            inverted
            @click="clickInvoiceDetails()">
            {{translations.invoiceDetailsText}}
          </bb-button>
        </div>
        <bb-spinner v-if="isLoading" />
        <info-box :data="infoBoxData" v-else-if="showTopInfoBox" />
        <sign-change
          v-if="contract.contractChangeSigning || hasNotificationStatus"
          class="signing-banner"
          :contract="contract"
          :notificationStatus="notificationStatus"
          @startSigning="handleSignClick"
        />
        <details-box
          v-if="showDetailsBox"
          :data="[{
            label: translations.loanAmount,
            value: loanAmount
          }, {
            label: translations.remainingPeriod,
            value: remainingPeriod
          }, {
            label: translations.remainingBalance,
            value: remainingBalanceFormatted,
            showLoaderOnNull: true,
            tooltipText: this.translations.repaymentTooltip
          }]"
        />
        <bb-list-group class="_info">
          <bb-list-item v-for="(detail, i) in details" :key="i"
            condensed
            :title-uppercase="false"
            class="color-gray"
            :class="detail.class || ''"
            :title="detail.title">
            <span class="color-blue f-small" :class="{'f-color-red': detail.isRed}">{{detail.value}}</span>
            <bb-tooltip v-if="detail.tooltip">{{detail.tooltip}}</bb-tooltip>
          </bb-list-item>
        </bb-list-group>
        <template v-if="isRevolvingLoan">
          <div class="_header contract-details">
            <div class="_title">
              <h3 class="contract_details__title">
                <span class="hide-mobile">{{translations.contractDetails}}</span>
              </h3>
            </div>
            <div class="_button">
                <bb-button
                  v-if="showManageMyLoanButton"
                  @click="showManageRevolvingLoanModal = true"
                  class="card-links--invoice-details"
                  color="blue"
                  display="block"
                  inverted>
                  {{translations.manageMyLoan}}
                </bb-button>
              </div>
          </div>
          <div class="contract-details">
            <info-box :data="contractDetailsData" />
          </div>
        </template>
        <template v-else>
          <div class="_header schedule-details">
            <div class="_title">
              <h3 class="contract_details__title">
                {{translation('scheduleDetailsTitle')}}
              </h3>
            </div>
            <div class="_button">
              <bb-button
                v-if="showManageMyLoanButton"
                @click="showManageLoanModal = true"
                class="card-links--invoice-details"
                color="blue"
                display="block"
                inverted>
                {{translations.manageMyLoan}}
              </bb-button>
            </div>
          </div>
          <div class="schedule-details">
            <euribor-notice v-if="isHousingLoan" class="m-b-30" :contract="contract" />
            <info-box :data="scheduleDetailsData" />
            <p class="schedule-details__notice" v-if="contract.isMigrated">
              <translate :translate-params="{date: migrationDate}">Schedule data is available as of %{date}.</translate>
            </p>
          </div>
        </template>
        <template v-if="documents && documents.length > 0">
          <h3 class="documents-section contract_details__title">{{translations.documentsTitle}}</h3>
          <div class="documents-section">
            <document-list :files="documents" :downloadHandler="download" />
          </div>
        </template>
      </div>
    </div>
    <component
      v-if="showInvoiceDetailsModal"
      :is="computedInvoiceDetailsModal"
      :contract="contract"
      @close="showInvoiceDetailsModal = !showInvoiceDetailsModal"
    />
    <div v-if="!contract" class="loan-schedule__loader">
      <bb-spinner />
    </div>
    <review-contract-signing-modal
      v-if="contract"
      :visible="showReviewContractModal"
      :contract="contract"
      :contractId="contractId"
      @close="showReviewContractModal = !showReviewContractModal"
    />
    <manage-revolving-loan-modal
      v-if="contract && showManageRevolvingLoanModal"
      :contract="contract"
      @close="showManageRevolvingLoanModal = false"
      visible
    />
    <manage-loan-modal
      v-if="contract && showManageLoanModal"
      :contract="contract"
      @close="showManageLoanModal = false"
      visible
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { formatInterest, formatMoneyWithCurrency } from '@/plugins/numformat'
import { formatDate } from '@/plugins/dateFormatters'
import { titleCase } from '@/plugins/utils'
import { getTranslation } from '@/plugins/translations'
import { REVOLVING_DASHBOARD } from '@/TrackingActions'

import api from '@loan/api'
import InfoBox from '@deposits/components/InfoBox'
import DetailsBox from '@deposits/components/DetailsBox'
import ConsumerLoanPayBack from '@/modules/loan/views/ConsumerLoanPayBack'
import RevolvingLoanPayback from '@/modules/loan/views/revolving/PayBack'
import SignChange from '@loan/components/SignChange'
import EuriborNotice from '@loan/components/EuriborNotice'
import ReviewContractSigningModal from '@loan/components/signing/ReviewContractSigningModal'
import ManageRevolvingLoanModal from '@loan/components/ManageRevolvingLoanModal'
import ManageLoanModal from '@loan/components/ManageLoanModal'
import DocumentList from '@/components/DocumentList'
import invoiceDetailsTogglerMixin from '@/mixins/invoiceDetailsTogglerMixin'
import { CountryChannel } from '@bigbank/dc-common/config'

import '@bigbank/interface-components/dist/svg/client/pdf'
import { useRootStore } from '../../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  name: 'ContractDetails',
  components: {
    RevolvingLoanPayback,
    InfoBox,
    DetailsBox,
    ConsumerLoanPayBack,
    SignChange,
    EuriborNotice,
    ReviewContractSigningModal,
    ManageLoanModal,
    ManageRevolvingLoanModal,
    DocumentList
  },
  mixins: [
    invoiceDetailsTogglerMixin
  ],
  data () {
    return {
      contractId: null,
      isLoading: false,
      showInvoiceDetailsModal: false,
      showManageLoanModal: false,
      showManageRevolvingLoanModal: false,
      showReviewContractModal: false,
      period: 'months',
      documents: [],
      remainingBalance: null,
      signingStatus: {},
      pollCount: 0,
      pollTimeoutInstance: null,
      POLL_INTERVAL: 5, // seconds
      MAX_POLL_COUNT: 180,
      repaymentDetails: {},
      insurancePolicyInfo: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['isCompany', 'locale', 'channel']),
    ...mapState(useLoanStore, ['featureFlags', 'contract']),
    isRevolvingLoan () {
      return [InternalLoanType.REVOLVING].includes(this.contract.typeCode)
    },
    isHousingLoan () {
      return [InternalLoanType.HOUSING].includes(this.contract.typeCode)
    },
    isFixedRate () {
      return this.contract.rateBaseCode === 'FIX'
    },
    contractNumber () {
      return this.contract.contractNumber
    },
    showInvoiceDetailsButton () {
      return !(this.contract.isInDebt || this.contract.isClosed) && !this.contract.isTerminated
    },
    showManageMyLoanButton () {
      let buttonEnabled = this.isRevolvingLoan || this.featureFlags.enableManageMyLoan
      const isActiveLoan = !(this.contract.isClosed || this.contract.isTerminated)

      if (this.isHousingLoan) {
        buttonEnabled = this.featureFlags.enableManageMyLoanButtonForHousingLoan
      }

      return isActiveLoan && buttonEnabled
    },
    infoBoxData () {
      return [{
        label: this.translations.nextDueDate,
        value: formatDate(this.contract.nextPaymentDate)
      }, {
        label: this.translations.nextMonthlySum,
        value: formatMoneyWithCurrency(this.contract.nextPaymentAmount, this.contract.currency, this.locale)
      }, {
        label: this.translations.iban,
        value: this.repaymentDetails.beneficiaryIban,
        showCopyButton: true
      }, {
        label: this.translations.referenceNumber,
        value: this.repaymentDetails.referenceNumber,
        showCopyButton: true
      }, {
        label: this.translations.directDebit,
        value: this.repaymentDetails.directDebitValid ? this.translations.on : this.translations.off,
        hide: !this.showDirectDebit
      }]
    },
    scheduleDetailsData () {
      const paymentDay = this.contract.paymentDay
        ? ((this.contract.paymentDay < 10 ? '0' : '') + this.contract.paymentDay)
        : '-'

      return [{
        label: this.translations.startDate,
        value: formatDate(this.contract.factualActivationDate)
      }, {
        label: this.translations.paymentDay,
        value: paymentDay
      }, {
        label: this.translations.endDate,
        value: formatDate(this.contract.factualEndDate || this.contract.endDate)
      }, {
        label: this.translations.contractStatus,
        value: this.translations.statusCode[this.contract.statusCode]
      }]
    },
    contractDetailsData () {
      return [{
        label: this.translations.activationDate,
        value: formatDate(this.contract.factualActivationDate)
      }, {
        label: this.translations.paymentDay,
        value: (this.contract.paymentDay < 10 ? '0' : '') + this.contract.paymentDay
      }, {
        label: this.translations.contractStatus,
        value: this.translations.statusCode[this.contract.statusCode]
      }]
    },
    insurancePolicies () {
      const { insurances, isInsuranceRequired } = this.insurancePolicyInfo ?? {}
      if (!Array.isArray(insurances) || this.channel === CountryChannel.LT) {
        return []
      } else if (insurances.length === 0) {
        return [{
          title: this.translations.insurancePolicy,
          value: isInsuranceRequired ? this.translations.insurancePolicyMissing : '-',
          tooltip: isInsuranceRequired ? this.translations.insurancePolicyTooltip : false,
          isRed: isInsuranceRequired,
          visible: true
        }]
      } else {
        return insurances.map(policyInfo => {
          return {
            title: this.translations.insurancePolicyEndDate.replace('{policyNumber}', policyInfo.policyNumber),
            value: policyInfo.expireDate,
            tooltip: this.translations.insurancePolicyTooltip,
            isRed: policyInfo.expiresSoon,
            visible: true
          }
        })
      }
    },
    details () {
      return [{
        title: this.translations.limitAmount,
        value: this.limitAmount,
        visible: this.isRevolvingLoan
      }, {
        title: this.translations.availableLimit,
        value: this.availableLimit,
        visible: this.isRevolvingLoan
      }, {
        title: this.translations.loanAmount,
        value: this.loanAmount,
        visible: !this.isRevolvingLoan,
        class: 'hidden-desktop'
      }, {
        title: this.translations.remainingPeriod,
        value: this.remainingPeriod,
        visible: !this.isRevolvingLoan,
        class: 'hidden-desktop'
      }, {
        title: this.translations.remainingBalance,
        value: this.remainingBalanceFormatted,
        visible: true,
        class: `hidden-desktop${this.isRevolvingLoan ? ' hidden-mobile' : ''}`
      }, {
        title: this.translations.product,
        value: this.product,
        visible: true
      }, {
        title: this.translations.interestType,
        value: this.interestType,
        visible: this.isHousingLoan
      }, {
        title: this.translations.euriborRate,
        value: this.euriborRate,
        visible: this.isHousingLoan && !this.isFixedRate
      }, {
        title: this.translations.marginRate,
        value: this.marginRate,
        visible: this.isHousingLoan && !this.isFixedRate
      }, {
        title: this.translations[this.isHousingLoan ? 'interestHousing' : 'interest'],
        value: this.interest,
        visible: true
      }, {
        title: this.translations.nextEuriborFixingDate,
        value: this.nextEuriborFixingDate,
        visible: this.isHousingLoan && !this.isFixedRate
      }, ...this.insurancePolicies, {
        title: this.translations.administrationFee,
        value: this.administrationFee,
        visible: this.contract.adminFee,
        tooltip: this.isRevolvingLoan && this.translations.revolvingLoanAdminFeeTooltip
      }, {
        title: this.translations.conclusionFee,
        value: this.conclusionFee,
        visible: this.contract.conclusionFee && !this.isRevolvingLoan
      }, {
        title: this.translations.coBorrower,
        value: this.coBorrower,
        visible: this.coBorrower
      }].filter(({ visible }) => visible)
    },
    migrationDate () {
      return formatDate(this.contract.migrationDate)
    },
    nextEuriborFixingDate () {
      return formatDate(this.contract.nextEuriborFixingDate)
    },
    loanAmount () {
      return formatMoneyWithCurrency(this.contract.loanAmount, this.contract.currency, this.locale)
    },
    availableLimit () {
      return formatMoneyWithCurrency(this.contract.availableLimit, this.contract.currency, this.locale)
    },
    limitAmount () {
      return formatMoneyWithCurrency(this.contract.limitAmount, this.contract.currency, this.locale)
    },
    remainingPeriod () {
      return this.contract.remainingPayments + ' / ' + this.contract.numberOfPayments + ' ' + this.periodCode
    },
    remainingPrinciple () {
      return formatMoneyWithCurrency(this.contract.remainingPrincipal, this.contract.currency)
    },
    remainingBalanceFormatted () {
      return formatMoneyWithCurrency(this.remainingBalance, this.contract.currency, this.locale)
    },
    product () {
      const name = this.contract.productName
      return getTranslation(name, ['PRODUCT_NAME'], name, this.isCompany)
    },
    interest () {
      return formatInterest(this.contract.interestRate, this.locale, true)
    },
    euriborRate () {
      return formatInterest(this.contract.euriborRate, this.locale, true)
    },
    marginRate () {
      return formatInterest(this.contract.marginRate, this.locale, true)
    },
    interestType () {
      return this.translations.interestTypes[this.contract.rateBaseCode] || '-'
    },
    administrationFee () {
      return formatMoneyWithCurrency(this.contract.adminFee, this.contract.currency, this.locale)
    },
    conclusionFee () {
      return formatMoneyWithCurrency(this.contract.conclusionFee, this.contract.currency, this.locale)
    },
    coBorrower () {
      return titleCase(this.contract.coBorrower)
    },
    total () {
      return formatMoneyWithCurrency(this.contract.initialPrincipalAmount, this.contract.currency, this.locale)
    },
    periodCode () {
      switch (this.period) {
        case 'days':
          return this.$pgettext('period_code', 'd')
        case 'months':
          return this.$pgettext('period_code', 'mo')
        default:
          return ''
      }
    },
    signingDate () {
      return formatDate(this.contract.signingDate)
    },
    translations () {
      return {
        contractTitle: this.$pgettext('loan_contract_details', 'Contract'),
        contractDetails: this.$pgettext('loan_contract_details', 'Contract Details'),
        invoiceDetailsText: this.$gettext('Invoice Details'),
        nextDueDate: this.$pgettext('loan_contract_details', 'Due Date'),
        manageMyLoan: this.$pgettext('loan_contract_details', 'Manage my Loan'),
        nextMonthlySum: this.$pgettext('loan_contract_details', 'Next Monthly Sum'),
        iban: this.$gettext('IBAN'),
        referenceNumber: this.$pgettext('loan_contract_details', 'Reference number'),
        directDebit: this.$pgettext('loan_contract_details', 'Direct debt'),
        loanAmount: this.$pgettext('loan_contract_details', 'Initial Loan Amount'),
        limitAmount: this.$pgettext('loan_contract_details', 'Credit Limit'),
        availableLimit: this.$pgettext('loan_contract_details', 'Available Limit'),
        remainingPeriod: this.$pgettext('loan_contract_details', 'Remaining period'),
        remainingPrinciple: this.$pgettext('loan_contract_details', 'Remaining Principle'),
        remainingBalance: this.$pgettext('loan_contract_details', 'Remaining Loan Balance'),
        product: this.$pgettext('loan_contract_details', 'product'),
        interest: this.$pgettext('loan_contract_details', 'Interest'),
        interestHousing: this.$pgettext('loan_contract_details-housing_loan', 'Interest'),
        euriborRate: this.$pgettext('loan_contract_details', 'Current base interest rate'),
        marginRate: this.$pgettext('loan_contract_details', 'Margin'),
        nextEuriborFixingDate: this.$pgettext('loan_contract_details', 'Next interest rate amendment date'),
        interestType: this.$pgettext('loan_contract_details', 'Interest Type'),
        administrationFee: this.$pgettext('loan_contract_details', 'Account Administration Fee'),
        conclusionFee: this.$pgettext('loan_contract_details', 'Conclusion Fee'),
        coBorrower: this.$gettext('Co-borrower'),
        total: this.$pgettext('loan_contract_details', 'Total Amount Repayment'),
        documentsTitle: this.$pgettext('loan_contract_details', 'Documents'),
        CONTRACT_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_SIGNED'),
        CONTRACT_APX_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_APX_SIGNED'),
        CONTRACT_PAY_APX_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_PAY_APX_SIGNED'),
        CONTRACT_OFFICE_APX_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_OFFICE_APX_SIGNED'),
        CONTRACT_AMENDMENT_RE_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_AMENDMENT_RE_SIGNED'),
        CONTRACT_RE_SIGNED: this.$pgettext('loan_contract_details', 'CONTRACT_RE_SIGNED'),
        AGREEMENTS: {
          OTHER_AGREEMENT: this.$pgettext('loan_contract_details', 'AGREEMENTS__OTHER_AGREEMENT')
        },
        dateCreated: this.$pgettext('loan_contract_details', 'Document created on'),
        copied: this.$gettext('Copied!'),
        on: this.$gettext('on'),
        off: this.$gettext('off'),
        startDate: this.$pgettext('loan_contract_details', 'Start Date'),
        activationDate: this.$pgettext('loan_contract_details', 'Activation Date'),
        endDate: this.$pgettext('loan_contract_details', 'End Date'),
        paymentDay: this.$pgettext('loan_contract_details', 'Payment Day'),
        contractStatus: this.$pgettext('loan_contract_details', 'Contract Status'),
        revolvingLoanAdminFeeTooltip: this.$pgettext('revolving_loan_admin_fee_tooltip', 'Applies in case you are using the credit limit'),
        statusCode: {
          ACTIVE: this.$pgettext('loan_status', 'Active'),
          ACTIVE_CH: this.$pgettext('loan_status', 'Active (in change)'),
          DRAFT: this.$pgettext('loan_status', 'Draft'),
          PREPARED: this.$pgettext('loan_status', 'Prepared'),
          SIGNED: this.$pgettext('loan_status', 'Signed'),
          ANNULLED: this.$pgettext('loan_status', 'Annulled'),
          CLOSED: this.$pgettext('loan_status', 'Closed')
        },
        interestTypes: {
          FIX: this.$pgettext('interest_type', 'FIX'),
          '3m': this.$pgettext('interest_type', '3 months euribor'),
          '6m': this.$pgettext('interest_type', '6 months euribor'),
          '12m': this.$pgettext('interest_type', '12 months euribor')
        },
        scheduleDetailsTitle: {
          [InternalLoanType.REVOLVING]: this.$pgettext('loan_contract_details_revolving', 'Schedule Details'),
          DEFAULT: this.$pgettext('loan_contract_details', 'Schedule Details')
        },
        repaymentTooltip: this.$gettext(
          'If You wish to repay your contract prematurely, please contact us via phone or email, ' +
          'so that we could calculate the exact sum needed to be paid.'
        ),
        insurancePolicyEndDate: this.$pgettext('loan_contract_details', 'Insurance Nr. {policyNumber} End Date'),
        insurancePolicyTooltip: this.$pgettext('loan_contract_details', 'Please make sure to renew your insurance at least 5 days before it\'s end date.'),
        insurancePolicy: this.$pgettext('loan_contract_details', 'Insurance policy'),
        insurancePolicyMissing: this.$pgettext('loan_contract_details', 'Policy missing')
      }
    },
    showDirectDebit () {
      return [true, false].includes(this.repaymentDetails.directDebitValid)
    },
    showTopInfoBox () {
      return !this.isRevolvingLoan && !this.contract.isTerminated
    },
    showDetailsBox () {
      return !this.isRevolvingLoan
    },
    hasNotificationStatus () {
      return !!(this.featureFlags.showContractSigningNotification && this.signingStatus?.notificationStatus)
    },
    notificationStatus () {
      return this.signingStatus?.notificationStatus
    }
  },
  watch: {
    contract (val) {
      if (val.hideDetails) {
        this.$router.push('/oops/404')
      }
    },
    $route: {
      immediate: true,
      handler () {
        this.contractId = this.$route.params.contractId
      }
    },
    contractId: {
      immediate: true,
      handler: async function (newId, oldId) {
        if (!newId || newId === oldId) {
          return
        }

        this.getContract(this.contractId)
        await this.fetchData()
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContract']),
    async fetchData () {
      try {
        this.isLoading = true
        const [repaymentDetails, documents, details] = await Promise.all([
          api.getRepaymentDetails(this.contractId),
          api.getContractDocuments(this.contractId).catch(() => ([])),
          api.getLoanContractById(this.contractId)
        ])
        this.documents = documents.map(document => {
          return {
            dateTitle: this.translations.dateCreated + ' ' + document.date,
            title: this.getDocumentTitle(document),
            ...document
          }
        })
        this.remainingBalance = details.remainingBalance
        this.insurancePolicyInfo = details.insurancePolicyInfo
        this.repaymentDetails = repaymentDetails
      } catch (err) {
        // Ignore - partial failure you know ;)
      } finally {
        this.isLoading = false
      }
    },
    getDocumentTitle (document) {
      return this.translations[document.printoutTypeCode] ||
        this.translations[document.type]?.[document.subtype] ||
        document.fileName
    },
    async clickInvoiceDetails () {
      this.showInvoiceDetailsModal = !this.showInvoiceDetailsModal
    },
    async download (file) {
      if (this.isRevolvingLoan) {
        this.$tracker.action(
          REVOLVING_DASHBOARD.REVOLVING_DOWNLOAD_CONTRACT_FILE,
          {},
          {
            contractId: this.contract.id.toString(),
            fileId: file.fileId
          }
        )
      }
      await api.initFileDownload(this.contract.id, file)
    },
    handleSignClick () {
      this.showReviewContractModal = true
    },
    startAfterSigningPolling () {
      if (this.featureFlags.showContractSigningNotification && this.signingRequestId) {
        this.pollStatus(0)
      }
    },
    pollStatus (delay) {
      if (this.pollCount > this.MAX_POLL_COUNT) {
        clearTimeout(this.pollTimeoutInstance)
      }

      const signingRequestId = this.signingRequestId
      const contractId = this.contractId

      this.pollTimeoutInstance = setTimeout(async () => {
        try {
          this.signingStatus = await api.getSigningStatus(contractId, signingRequestId)
          this.pollCount++
          this.pollStatus(this.POLL_INTERVAL * 1000)
        } catch (err) {
          console.error(err)
        }
      }, delay)
    },
    translation (key) {
      const value = this.translations[key]
      if (typeof value === 'object' && value !== null) {
        return value[this.contract.typeCode] || value.DEFAULT || value
      }
      return value
    }
  },
  async mounted () {
    this.signingRequestId = this.$route.params.signingRequestId || this.$route.query.signingRequestId
    if (this.signingRequestId) {
      this.startAfterSigningPolling()
    }
  },
  beforeDestroy () {
    clearTimeout(this.pollTimeoutInstance)
  }
}
</script>

<style lang="scss">
  .loan_contract_details {
    .hide-mobile {
      display: none;

      @media (min-width: $desktop-view-breaking-point) {
        display: block;
      }
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
      margin-top: 30px;
    }

    &__title {
      color: $navy;
      font-family: $max-pro-demi-bold;
    }

    &__wrapper {
      .contract_details__content {
        padding: 5px 15px 126px;
        display: flex;
        gap: 30px;
        flex-direction: column;

        .contract_details__title {
          color: $navy;
          font-family: $max-pro-demi-bold;
        }

        @media (min-width: $desktop-view-breaking-point) {
          max-width: 850px;
          margin: auto;
          width: 100%;
          padding: 40px 15px 96px;
        }

        ._header {
          display: flex;
          flex-direction: column;
          gap: 30px;

          @media (min-width: $desktop-view-breaking-point) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }

        .invoice-details-button-mobile {
          order: 3;

          @media (min-width: $desktop-view-breaking-point) {
            display: none;
          }
        }

        ._button {
          text-align: right;
        }

        .info-box {
          order: 1;

          .item:nth-of-type(5) {
            .value {
              text-transform: uppercase;
            }
          }

          @media (min-width: $desktop-view-breaking-point) {
            width: 100%;
          }
        }

        .details-box {
          order: 4;
        }

        .bb-list-group {
          order: 5;

          .bb-list-item {
            &__title {
              font-size: $font-size-smallest !important; // Don't blame me, blame the design

              &::first-letter {
                text-transform: capitalize;
              }
            }
          }

          @media (min-width: $desktop-view-breaking-point) {
            .bb-list-item {
              &__content {
                padding: 0.25rem 0;
              }

              &__title {
                font-size: $mobile-default-font-size;
              }

              &__default-slot {
                color: $navy;
                font-size: $default-font-size;
              }
            }
          }
        }

        .schedule-details {
          order: 6;

          &__notice {
            font-size: $font-size-tiny;
            margin-top: 15px;
            color: $gray-90;
          }
        }

        .contract-details {
          order: 6;
        }

        .documents-section {
          order: 7;
        }
      }
    }

    .signing-banner {
      order: 3;
    }
  }
</style>
