<template>
    <div class="empty-state-page text-center bg-white">
      <bb-hero class="empty-state-page__hero" color="mint-10" bleed="all"/>
      <div class="empty-state-page__card d-flex justify-content-center">
        <bb-credit-card status="empty"/>
      </div>
      <div class="m-t-40">
        <p class="f-gotham-medium color-gray">{{ translations.title }}</p>
        <p class="m-t-10 f-small color-gray-80">{{ translations.message }}</p>
      </div>
      <bb-row class="empty-state-page__offer-overview m-t-30">
        <div class="empty-state-page__offer-overview--pricing">
          <p class="f-gotham-bold f-tiny f-case-upper color-gray-70">{{ translations.monthlyCostTitle }}</p>
          <h4 class="f-gotham-medium color-gray" v-if="isPricingLoaded">{{ formattedMonthlyCost }}</h4>
        </div>
        <div class="empty-state-page__offer-overview--pricing">
          <p class="f-gotham-bold f-tiny f-case-upper color-gray-70">{{ translations.paymentFreePeriodTitle }}</p>
          <h4 class="f-gotham-medium color-gray" v-if="isPricingLoaded">{{ translations.paymentFreePeriod }}</h4>
        </div>
        <div class="empty-state-page__offer-overview--pricing">
          <p class="f-gotham-bold f-tiny f-case-upper color-gray-70">{{ translations.lowInterestRateTitle }}</p>
          <h4 class="f-gotham-medium color-gray" v-if="isPricingLoaded">{{ translations.interestRate }}</h4>
        </div>
      </bb-row>
      <bb-button class="m-t-45 m-b-50" :label="translations.buttonText" :to="continueToApplicationLink" target="_blank" />
    </div>
</template>

<script>
import { BbCreditCard } from '@bigbank/interface-components'
import { mapState, mapActions } from 'pinia'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { translationPicker } from '@/plugins/translations'
import { every, isNull } from 'lodash'
import { useRootStore } from '@/store/root'
import { useCreditCardStore } from '@/store/modules/credit-card'

export default {
  name: 'empty-state-page',
  components: {
    BbCreditCard
  },
  computed: {
    ...mapState(useRootStore, ['channel', 'language', 'currencySymbol', 'channelConfig']),
    ...mapState(useCreditCardStore, ['creditCardPricing']),
    translations () {
      return {
        title: this.$pgettext('credit_card_empty', 'Get your VISA Classic for free!'),
        message: this.$pgettext('credit_card_empty', 'Get credit card for free to do multiple cool things.'),
        monthlyCostTitle: this.$pgettext('credit_card_empty', 'Monthly cost'),
        paymentFreePeriodTitle: this.$pgettext('credit_card_empty', 'Payment free period'),
        lowInterestRateTitle: this.$pgettext('credit_card_empty', 'Low interest rate'),
        buttonText: this.$pgettext('credit_card_empty', 'Order own credit card'),
        paymentFreePeriod: this.$gettextInterpolate(this.$pgettext('credit_card_empty', '%{paymentFreePeriod} days'), { paymentFreePeriod: this.formattedPaymentFreePeriod }),
        interestRate: this.$gettextInterpolate(this.$pgettext('credit_card_empty', '%{interestRate}% yearly'), { interestRate: this.formattedBaseInterest })
      }
    },
    continueToApplicationLink () {
      return translationPicker(this.channelConfig.continueToApplicationUrl[InternalLoanType.CREDIT_CARD], this.language)
    },
    formattedMonthlyCost () {
      return `${this.creditCardPricing.monthlyFee} ${this.currencySymbol}`
    },
    formattedPaymentFreePeriod () {
      return this.creditCardPricing.maxInterestFreePeriod ?? 0
    },
    formattedBaseInterest () {
      return this.creditCardPricing.baseInterest ?? 0
    },
    isPricingLoaded () {
      return !every(this.creditCardPricing, isNull)
    }
  },
  methods: {
    ...mapActions(useCreditCardStore, ['fetchCreditCardPricing'])
  },
  async mounted () {
    if (!this.isPricingLoaded) {
      await this.fetchCreditCardPricing()
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-state-page {
  border-radius: rem(10px);
  margin-bottom: 44px;

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 32px;
  }

  &__hero {
    height: rem(200px);
    border-radius: rem(10px) rem(10px) 0 0;
  }

  &__card {
    margin-top: -111px;

    @media (max-width: $breakpoint-sm) {
      margin-top: -100px;
    }
  }

  &__offer-overview {
    justify-content: center;
    column-gap: 75px;

    &--pricing {
      min-height: 51px;
    }

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}
</style>
