<template>
  <bb-modal visible @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile class="schedule-modal">
    <div slot="default" class="schedule-modal__title f-small">{{title}}</div>
    <div class="schedule-modal__total-amount  m-t-25" :class="{'color-red': type === 'debt', 'color-navy': type !== 'debt'}">
      {{ formattedTotalAmount }}
    </div>
    <div class="schedule-modal__due-date">
      <span><translate>Due Date</translate>: {{ formattedDate }}</span>
    </div>
    <bb-list-group class="invoice__details-list m-t-30">
      <bb-list-item
        v-for="(component, k) in filteredComponents"
        :key="k" extraCondensed
        :title="component.title">
          <span :class="{'color-red': component.debt}">
            {{ formatAmount(component.amount, component.currency) }}
          </span>
      </bb-list-item>
    </bb-list-group>
    <div class="m-t-30" v-if="filteredReceived.length > 0">
      <h3 class="subtitle">
        <translate translate-context="schedule_modal_subtitle">Payment received:</translate>
      </h3>
      <bb-list-item
        v-for="(component, k) in filteredReceived"
        :key="k" extraCondensed
        :title="component.title">
          <span :class="{'color-red': component.debt}">{{ formatAmount(component.amount, component.currency) }}</span>
        </bb-list-item>
    </div>
    <div v-if="showPaymentDetails" class="schedule-modal__payment-details m-t-15">
      <h3 class="subtitle">
        <translate>Payment details:</translate>
      </h3>
      <payment-details v-if="paymentDetails" v-bind="paymentDetails" />
      <div v-else>
        <bb-spinner small/>
      </div>
    </div>

    <div v-if="showPaid">
      <h3 class="subtitle m-t-30">
        <translate>Payment details:</translate>
      </h3>
      <bb-box thin class="schedule-modal__paid">
        <bb-icon-frame
          class="schedule-modal__paid__icon"
          filled
          colored-frame
          icon="ui-check-circle-outline"
          icon-color="green"
        />
        <div>
          <span class="schedule-modal__paid__date"><translate>Payment Date</translate>: {{ formattedPaidDate }}</span>
          <span class="schedule-modal__paid__status">{{paidStatusText}}</span>
        </div>
      </bb-box>
    </div>
    <bb-ticket-stub slot="footer" class="nm-x-20 nm-b-md-20">
      <bb-button display="ticket" color="white" @click="onClose()">
        <span class="color-green"><translate>Got it</translate></span>
      </bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { INVOICE_MODAL, REVOLVING_DASHBOARD } from '@/TrackingActions'
import PaymentDetails from './PaymentDetails'
import { useRootStore } from '../../../store/root'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  components: {
    PaymentDetails
  },
  data () {
    return {
      paymentDetailsData: null
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    totalAmount: {
      require: true
    },
    currency: {
      require: true
    },
    dueDate: {
      required: true
    },
    productTypeCode: {
      required: true
    },
    paidDate: {
      required: false
    },
    components: {
      type: Array,
      required: true
    },
    received: {
      type: Array,
      required: false
    },
    paymentDetailsResolver: {
      type: Function,
      required: false
    },
    translations: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    title () {
      return this.translations.title[this.type]
    },
    formattedDate () {
      return new Date(this.dueDate).toLocaleDateString(this.locale)
    },
    formattedPaidDate () {
      return new Date(this.paidDate).toLocaleDateString(this.locale)
    },
    formattedTotalAmount () {
      return this.formatAmount(this.totalAmount)
    },
    filteredComponents () {
      return this.components.filter(({ amount }) => amount !== 0)
    },
    filteredReceived () {
      if (!this.received || !this.received.length) {
        return []
      }

      return this.received.filter(({ amount }) => amount !== 0)
    },
    showPaymentDetails () {
      return ['debt', 'invoice'].includes(this.type)
    },
    showPaid () {
      return ['paid', 'paid-late'].includes(this.type)
    },
    paymentDetails () {
      if (!this.paymentDetailsData) {
        this.resolvePaymentDetails()
        return null
      }

      return this.paymentDetailsData
    },
    paidStatusText () {
      if (this.type === 'paid-late') {
        return this.translations.paidLateStatus
      }
      return this.translations.paidStatus
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    formatAmount (amount, currency) {
      return formatMoneyWithCurrency(amount, currency || this.currency, this.locale)
    },
    async resolvePaymentDetails () {
      this.paymentDetailsData = await this.paymentDetailsResolver()
    }
  },
  mounted () {
    let action = INVOICE_MODAL.VIEW

    if (this.productTypeCode === InternalLoanType.REVOLVING) {
      action = REVOLVING_DASHBOARD.REVOLVING_VIEW_INVOICE_MODAL
    }

    this.$tracker.action(action, { type: this.type })
  }
}
</script>

<style lang="scss">
.schedule-modal {
  .subtitle {
    color: $gray;
    text-transform: uppercase;
    text-align: center;
    font-size: $font-size-small;
    font-family: $gotham-medium;
    margin-top: 35px;
  }

  &__title {
    color: $blue;
    text-align: center;
    font-family: $gotham-book;
    font-weight: bold;
  }

  &__total-amount {
    text-align: center;
    font-family: $gotham-medium;
    font-size: $h3-size;
  }

  &__due-date {
    color: $gray-70;
    font-family: $gotham-medium;
    text-align: center;
    font-size: $font-size-tiny;
    font-weight: 400;
  }

  &__paid {
    display: flex;
    line-height: $default-font-size;

    &__icon {
      margin-right: 15px;
    }

    &__date {
      margin-top: 3px;
      color: $gray-70;
      font-family: $gotham-medium;
      font-size: $font-size-smallest;
      display: block;
    }

    &__status {
      color: $gray;
      font-family: $gotham-medium;
      font-size: $font-size-small;
    }
  }
}
</style>
