<script>
import api from '@loan/api'
import TransactionsList from '../../../../components/TransactionsList.vue'
import transactionTypes from '../../../../types/transactions'
import transactionStatuses from '../../../../types/transaction-status'
import AccountType from '../../../../types/account.type'
import { uniqBy } from 'lodash'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  name: 'loan-transactions',
  extends: TransactionsList,
  data () {
    return {
      agreementTypeCodes: [AccountType.Current, AccountType.Leasing],
      types: [{
        text: this.$pgettext('money_out', 'Money Out'),
        types: [
          transactionTypes.ACC2SEPA_PAY
        ],
        value: 'MONEY_OUT'
      }, {
        text: this.$pgettext('money_in', 'Money In'),
        types: [
          transactionTypes.SEPA2ACC_PAY,
          transactionTypes.INTRA2ACC_PAY
        ],
        value: 'MONEY_IN'
      }, {
        text: this.$pgettext('transactions', 'Fee'),
        types: [
          transactionTypes.ACC2LOANFEE,
          transactionTypes.ACC2LOANDISBURSEMENT_FEE,
          transactionTypes.ACC2REPAYMENTKREDEX_FEE
        ],
        value: 'FEE',
        hideWhenNoTransactionsAvailable: true
      }]
    }
  },
  computed: {
    ...mapState(useLoanStore, ['featureFlags', 'contracts', 'loans']),
    showAccountInfoCard () {
      return this.featureFlags.showAccountInfoCard
    },
    emptyTransactionsText () {
      return this.$pgettext('loan_dashboard', 'As soon as you have transactions, they will be available here.')
    },
    transactionStatuses () {
      return [{
        label: this.translations.transactions,
        active: true,
        id: transactionStatuses.FINISHED,
        filters: this.showFilters
      }]
    },
    migrationDate () {
      return (this.contracts.find(({ isMigrated }) => isMigrated) || {}).migrationDate
    },
    hasAnyLoans () {
      return this.loans && this.loans.length > 0
    },
    visibleAccountIds () {
      return uniqBy(Array.isArray(this.loans) ? this.loans : [], loan => loan.servicingAccountId)
        .map(loan => loan.servicingAccountId)
    },
    areFiltersShownLocal () {
      return this.$route.name === 'loan-transactions'
    }
  },
  methods: {
    async loadTransactions () {
      if (this.hasAnyLoans) {
        return await api.getTransactions({
          loanTypes: [InternalLoanType.LOAN, InternalLoanType.HOUSING, InternalLoanType.REAL_ESTATE, InternalLoanType.LEASING],
          // load plenty of transactions from ABC
          pageSize: 10000
        })
      }

      return []
    }
  }
}
</script>
