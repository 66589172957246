<template>
  <bb-modal :visible="visible" @close="onClose()" flavor="card" color="white" publicSize="m" fullMobile>
    <div class="title">
      <translate translate-context="manage_my_loan_modal_title">Manage my Loan</translate>
    </div>
    <template v-if="prolongationInfo">
      <div class="icon-wrapper">
        <bb-icon animated fill="original" name="illustration-session-expired" size="fluid" />
      </div>
      <div v-if="text" class="text" v-sanitize.basic="text"></div>
      <bb-separator dashed />
        <bb-toggle-switch
          class="prolong-toggle"
          v-bind="toggle"
          v-model="form.automaticProlongationEnabled"
          @input="prolongationToggleChange"
          name="toggleAutomaticProlongation"
        />
      <bb-separator dashed />
    </template>
    <div class="spinner-wrapper" v-else>
      <bb-spinner />
    </div>
    <bb-ticket-stub v-if="prolongationInfo" slot="footer" class="m-t-15">
      <bb-button display="ticket" class="no-padding" v-bind="button" @click="submit">
        <translate>Save</translate>
      </bb-button>
    </bb-ticket-stub>
  </bb-modal>
</template>

<script>
import api from '@loan/api'
import { REVOLVING_DASHBOARD } from '@/TrackingActions'
import { mapState, mapActions } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import '@bigbank/interface-components/dist/svg/illustration/session-expired'

export default {
  name: 'manage-revolving-loan-modal',
  data () {
    return {
      isLoading: false,
      prolongationInfo: null,
      form: {
        automaticProlongationEnabled: null
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    contract: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        prolongInfo: this.$pgettext('manage_my_loan', 'Your contract will be automatically renewed on {date}.'),
        prolongInfoOff: this.$pgettext('manage_my_loan', 'Your contract will not be automatically prolonged with same conditions and will therefore end or changed to annuity on {date}.'),
        saveInfo: this.$pgettext('manage_my_loan', 'In order to change the prolongation status, click on Save.'),
        automaticProlongation: this.$pgettext('manage_my_loan', 'Automatic prolongation'),
        active: this.$pgettext('manage_my_loan', 'Active'),
        disabled: this.$pgettext('manage_my_loan', 'Disabled')
      }
    },
    text () {
      if (this.hasValueChanged) {
        return this.translations.saveInfo
      } else if (this.prolongationInfo.isAutomaticProlongationEnabled) {
        return this.translations.prolongInfo.replace('{date}', '<strong>' + this.contract.automaticProlongationDate + '</strong>')
      } else {
        return this.translations.prolongInfoOff.replace('{date}', '<strong>' + this.contract.automaticProlongationDate + '</strong>')
      }
    },
    toggle () {
      return {
        label: this.translations.automaticProlongation,
        showStatus: true,
        onStatusLabel: this.translations.active,
        offStatusLabel: this.translations.disabled,
        uncheckedColor: 'gray',
        disabled: !this.prolongationInfo.canChangeAutomaticProlongation
      }
    },
    button () {
      return {
        color: this.hasValueChanged && !this.isLoading ? 'green' : 'gray',
        loading: this.isLoading
      }
    },
    hasValueChanged () {
      const value = this.form.automaticProlongationEnabled
      return value !== null && value !== this.prolongationInfo.isAutomaticProlongationEnabled
    },
    isRevolvingLoan () {
      return this.contract.typeCode === InternalLoanType.REVOLVING
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getContracts']),
    async submit () {
      if (!this.hasValueChanged || this.isLoading) {
        return
      }

      this.isLoading = true
      await api.updateProlongation(this.contract.id, { ...this.form })
      this.isLoading = false

      if (this.isRevolvingLoan) {
        this.$tracker.action(
          REVOLVING_DASHBOARD.REVOLVING_CONFIRM_AUTOMATIC_PROLONGATION_CHANGE,
          {
            automaticProlongation: this.form.automaticProlongationEnabled
          },
          { contractId: this.contract.id.toString() }
        )
      }

      this.reset()
    },
    async reset () {
      this.isLoading = true
      try {
        this.prolongationInfo = await api.getProlongationInfo(this.contract.id)
        this.form.automaticProlongationEnabled = this.prolongationInfo.isAutomaticProlongationEnabled
      } catch (err) {}
      this.isLoading = false
    },
    onClose () {
      this.$emit('close')
    },
    prolongationToggleChange (value) {
      if (this.isRevolvingLoan) {
        this.$tracker.action(
          REVOLVING_DASHBOARD.REVOLVING_CHANGE_AUTOMATIC_PROLONGATION_TOGGLE,
          {
            automaticProlongation: value
          },
          { contractId: this.contract.id.toString() }
        )
      }
    }
  },
  mounted () {
    this.reset()
    this.$tracker.action(
      REVOLVING_DASHBOARD.REVOLVING_VIEW_MANAGE_MY_LOAN_MODAL,
      {}, { contractId: this.contract.id.toString() }
    )
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: $blue;
  font-size: $default-font-size;
  font-family: $gotham-bold;
  text-align: center;
}

.icon-wrapper {
  max-width: 200px;
  margin: auto;
  margin-top: 25px;
}

.text {
  font-size: $font-size-small;
  font-family: $gotham-book;
  text-align: center;
  color: $gray;
  margin-top: 40px;
  margin-bottom: 40px;
}

.prolong-toggle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
