import Transactions from './dashboard/Transactions.vue'
import ActiveContracts from './dashboard/ActiveContracts.vue'
import ClosedContracts from './dashboard/ClosedContracts.vue'
import Dashboard from './Dashboard.vue'
import RevolvingLoanView from './RevolvingLoanView.vue'
import ContractDetailsView from './ContractDetailsView.vue'
import Schedule from './contractDetails/Schedule.vue'
import Invoices from './contractDetails/Invoices.vue'
import ContractDetails from './contractDetails/ContractDetails'
import IncreaseLimit from './revolving/IncreaseLimit'
import PayBack from './revolving/PayBack'
import Transfer from './revolving/Transfer'
import ApplyThroughPartnerView from './apply-through-partner/ApplyThroughPartnerView'
import { featureRouteGuard } from '@/plugins/routeGuards'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { RouteNames } from './const'
import { CreditCardRouteName } from '@credit-card/views/const'
import { useRootStore } from '@/store/root'

const analytics = {
  product: 'loan'
}

export default [
  {
    path: '/loans',
    name: 'loans',
    beforeEnter: async (to, from, next) => {
      let name = RouteNames.ACTIVE_LOANS
      const rootStore = useRootStore()
      const creditProductEnabled = ['enableRevolvingLoan', 'enableCreditCardProduct']
        .some(feature => rootStore.featureFlags[feature])

      if (!creditProductEnabled) {
        next({ name })
      } else {
        const fallbackResponse = {
          totalByType: {
            contract: { all: {}, active: {} },
            application: {}
          },
          debt: { contractsInDebt: {}, highestDebtContractType: '', showCreditCardInDebtWarning: false, showLoanInDebtWarning: false }
        }
        const { totalByType, applications, debt } = (await rootStore.getContractsCount()) || fallbackResponse
        const products = [
          InternalLoanType.LOAN,
          InternalLoanType.REVOLVING,
          InternalLoanType.CREDIT_CARD
        ]

        name = RouteNames.ACTIVE_LOANS

        if (applications > 0) {
          if (totalByType.application.CREDIT_CARD > 0 && rootStore.featureFlags.enableShowCreditCardApplicationUnderCreditCardView) {
            name = CreditCardRouteName.CreditCard
          } else if (totalByType.application.LOAN === 0 && totalByType.application.REVOLVING > 0) {
            name = RouteNames.REVOLVING_LOAN
          }
        } else if (products.some(product => totalByType.contract.active[product] > 0)) {
          if (totalByType.contract.active.CREDIT_CARD > 0) {
            name = CreditCardRouteName.CreditCard
          } else if (totalByType.contract.active.LOAN === 0 && totalByType.contract.active.REVOLVING > 0) {
            name = RouteNames.REVOLVING_LOAN
          }
        } else if (products.some(product => totalByType.contract.all[product] > 0)) {
          if (totalByType.contract.all.CREDIT_CARD > 0) {
            name = CreditCardRouteName.CreditCard
          } else if (totalByType.contract.all.LOAN === 0 && totalByType.contract.all.REVOLVING > 0) {
            name = RouteNames.REVOLVING_LOAN
          }
        }

        if (debt.showCreditCardInDebtWarning && debt.showLoanInDebtWarning) {
          name = debt.highestDebtContractType === InternalLoanType.CREDIT_CARD ? CreditCardRouteName.CreditCard : RouteNames.ACTIVE_LOANS
        } else if (debt.showCreditCardInDebtWarning) {
          name = CreditCardRouteName.CreditCard
        } else if (debt.showLoanInDebtWarning) {
          name = RouteNames.ACTIVE_LOANS
        }

        next({ name })
      }
    }
  },
  {
    path: '/my-active-loans',
    name: 'loan',
    meta: {
      pinNavbar: true,
      analytics
    },
    component: Dashboard,
    children: [
      {
        path: '/my-active-loans',
        name: RouteNames.ACTIVE_LOANS,
        component: ActiveContracts,
        beforeEnter: async (to, from, next) => {
          const rootStore = useRootStore()
          const { hasCountryStoppedIssuingLoans } = rootStore

          if (hasCountryStoppedIssuingLoans) {
            const contractsCount = await rootStore.getContractsCount()
            const isCustomerWithoutActiveContracts = contractsCount?.activeContracts === 0

            if (isCustomerWithoutActiveContracts) {
              return next({ name: RouteNames.CLOSED_LOANS })
            }
          }

          return next()
        }
      },
      {
        path: 'closed',
        name: 'loan-closed',
        component: ClosedContracts
      },
      {
        path: 'transactions',
        name: 'loan-transactions',
        component: Transactions,
        beforeEnter: featureRouteGuard('enableTransactionsView', undefined, 'loan')
      }
    ]
  },
  {
    path: '/my-revolving-loan',
    name: RouteNames.REVOLVING_LOAN,
    meta: {
      pinNavbar: true,
      analytics
    },
    component: RevolvingLoanView,
    beforeEnter: featureRouteGuard('enableRevolvingLoan'),
    children: [{
      path: 'pay-back',
      name: 'revolving-loan_pay-back',
      component: PayBack,
      props: { onCloseRedirectUrl: RouteNames.REVOLVING_LOAN }
    }, {
      path: 'transfer',
      name: 'revolving-loan_transfer',
      component: Transfer
    }, {
      path: 'increase-limit',
      name: 'revolving-loan_increase-limit',
      component: IncreaseLimit
    }]
  },
  {
    path: ':contractId',
    component: ContractDetailsView,
    meta: {
      analytics
    },
    children: [
      {
        path: 'details',
        name: RouteNames.LOAN_CONTRACT_DETAILS,
        component: ContractDetails,
        beforeEnter: featureRouteGuard(['enableContractDetails', 'enableContractDetailsForRevolvingLoan'], undefined, 'loan')
      },
      {
        path: 'schedule',
        name: RouteNames.CONTRACT_SCHEDULE,
        component: Schedule,
        beforeEnter: featureRouteGuard(['enableContractDetails', 'enableContractDetailsForRevolvingLoan'], undefined, 'loan')
      },
      {
        path: 'invoices',
        name: 'loan-contract-invoices',
        component: Invoices,
        beforeEnter: featureRouteGuard(['enableContractDetails', 'enableContractDetailsForRevolvingLoan'], undefined, 'loan')
      }
    ]
  },
  {
    path: '/apply-through-partner',
    name: RouteNames.APPLY_THROUGH_PARTNER,
    component: ApplyThroughPartnerView,
    beforeEnter: featureRouteGuard('enableApplyThroughPartner'),
    meta: {
      analytics
    }
  }
]
