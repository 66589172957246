<template>
  <bb-modal
    :visible="visible"
    flavor="card"
    @close="onModalClosed"
    class="noselect text-center credit-card-order-modal"
    centerVertically
    fullMobile
  >
    <h5 class="text-center color-blue f-small f-gotham-medium">{{ translations.modal_title }}</h5>
    <template v-if="isLoading">
      <bb-spinner :size="90" v-if="isLoading"/>
    </template>
    <template v-else>
      <div>
        <bb-icon
          name="illustration-money"
          class="illustration-money"
          size="fluid"
          fill="mint-70"
        ></bb-icon>
        <span class="f-gotham-book color-gray f-small">{{ translations.please_check_your_contract }}</span>
        <bb-banner
          class="text-left m-t-25 m-b-10 f-gotham-book color-gray"
          type="info"
          :visible="true"
          permanent
        >
          <div v-sanitize.basic="translations.boxed_tooltip" ref="boxedTooltip" class="boxed-tooltip"></div>
        </bb-banner>
        <bb-banner
          class="text-left m-t-25 m-b-10 f-gotham-book color-gray"
          type="info"
          :visible="isGlobalLimitBannerVisible"
          permanent
        >
          <div>{{ translations.global_limit_tooltip }}</div>
        </bb-banner>
      </div>
      <div slot="footer">
        <bb-button
          name="sign-with-pin-and-show-cvv"
          :disabled="isLoading"
          @click="onSubmit"
          v-bind="submitButtonProps"
        >{{ translations.submit_button_label }}</bb-button>
      </div>
    </template>
  </bb-modal>
</template>

<script>
import api from '@/modules/loan/api'
import { mapState, mapActions } from 'pinia'
import { CardTransactionType, CardPeriodType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { useRootStore } from '@/store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { useCreditCardStore } from '@/store/modules/credit-card'

export default {
  name: 'virtual-card-info-modal',
  props: {
    visible: { type: Boolean, required: true },
    cardId: { type: Number, required: true },
    contractId: { type: String, required: true }
  },
  data () {
    return {
      isLoading: true,
      contractFileId: null,
      card: {
        limits: []
      },
      limits: {
        canUseWithoutAdditionalLimits: true,
        canOrderVirtualCard: false,
        remainingLimit: null
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'featureFlags']),
    availableCardLimit () {
      return this.card.limits.filter(item => item.periodType === CardPeriodType.MONTH && item.transactionType === CardTransactionType.ONLINE)[0]?.limitAmount
    },
    isGlobalLimitBannerVisible () {
      return this.featureFlags.enableUnverifiedCustomerAdditionalLimits && !this.limits.canUseWithoutAdditionalLimits
    },
    submitButtonProps () {
      return {
        display: 'block',
        size: this.isMobile ? 'lg' : undefined,
        class: this.isMobile ? 'm-s-a' : undefined,
        corner: this.isMobile ? 'sharp' : undefined
      }
    },
    translations () {
      return {
        modal_title: this.$pgettext('credit_card_order_virtual_card', 'Activate virtual card'),
        submit_button_label: this.$pgettext('credit_card_order_virtual_card', 'Sign with PIN2 and show CVV'),
        in_order_to_start_use_your_credit_card: this.$pgettext('credit_card_order_virtual_card', 'In order to start using your credit limit right away, you can get a free virtual card'),
        please_check_your_contract: this.$pgettext('credit_card_order_virtual_card', 'Please check your contract and after sign it with PIN2 to activate credit card'),
        boxed_tooltip: this.$pgettext('credit_card_order_virtual_card', 'Before activating the card, please check the <span>Card Contract Details</span>'),
        activation_warning: this.$pgettext('credit_card_order_virtual_card', 'Unfortunately you can not order a virtual card, because you do not have enough monthly transaction limit.'),
        global_limit_tooltip: this.$gettextInterpolate(this.$gettext(
          'Your new card\'s global limit will be %{amount}€. ' +
          'You can change it in the Self-Service when you have activated your card.'
        ), { amount: this.availableCardLimit ?? '' })
      }
    }
  },
  methods: {
    ...mapActions(useCreditCardStore, [
      'getAvailableMonthlyLimit',
      'refreshCreditCard'
    ]),
    setIsLoading (flag) { this.isLoading = flag },
    setContractFileId (id) { this.contractFileId = id },
    listenContractLinkClickEvent () {
      try {
        this.$refs.boxedTooltip.querySelector('span').addEventListener('click', async (event) => {
          await api.downloadCreditCardContract(this.contractId, this.contractFileId)
          event.preventDefault()
          event.stopPropagation()
          return false
        })
      } catch (err) {
        setTimeout(() => this.listenContractLinkClickEvent(), 50)
      }
    },
    onSubmit () {
      this.$emit('submit')
    },
    onModalClosed () {
      this.$emit('close')
    }
  },
  async mounted () {
    this.setIsLoading(true)
    this.limits = await this.getAvailableMonthlyLimit()
    const response = await api.generateCreditCardContract(this.cardId, this.contractId)
    this.card = await api.getCreditCardByCardId(this.cardId)
    this.setContractFileId(response.contractFileId)
    this.listenContractLinkClickEvent()
    this.setIsLoading(false)
  }
}
</script>
