<template>
  <div class="account-statement-transactions-wrapper">
    <div v-if="isLoading"></div>
    <bb-transactions
      v-if="viewport"
      :viewport="viewport"
      :is-filtered="isFiltered"
      :filters-reset-button-label="translations.resetButtonLabel"
      :filters-reset-label="translations.resetLabel"
      :filters-submit-button-disabled="isFiltersSubmitButtonDisabled"
      @filterSubmit="onStatementUpdateRequested"
      @filterReset="onFiltersReset"
      @downloadClick="onStatementDownloadRequested"
      @transactionItemClick="onTransactionItemClicked"
      @transactionItemButtonClick="onTransactionItemClicked"
      @footerStuck="onPageBottomReached"
      v-bind="bbTransactionsComponentProps"
      v-bind:filtersSubmitButtonLabel="changeFiltersSubmitButtonLabel"
    >
      <template #filters>
        <main-transaction-filter
          v-model="filters.form"
          :flavor="flavor"
          :dropdownItems="mainTransactionFilterDropdownItems"
          :datePickerMinDate="datePickerMinDate"
          :datePickerMaxDate="datePickerMaxDate"
          :datePickerMaxRange="datePickerMaxRange"
          :areFilterInputsDisabled="areFilterInputsDisabled"
          @on-date-picker-interval-change="onDatePickerIntervalChanged"
          @isValid="onMainTransactionFilterValid"
        />
      </template>
      <template #filtersExpanded>
        <advanced-transaction-filter
          v-model="filters.form"
          :areFilterInputsDisabled="areFilterInputsDisabled"
          :maxFiltersCount="maxFiltersCount"
          :isLoading="isLoading"
          :transactionsSettings="transactionSettings.transactionsFilteringAdvancedSearchOptionByDefault"
          :additionalOptionsCheckboxes="additionalOptionsCheckboxes"
          :accounts="mainTransactionFilterDropdownItems"
          :flavor="flavor"
          @on-period-change="onPeriodChanged"
        />
      </template>
      <template #underTransactionsList>
        <div class="text-center">
          <bb-button
            :label="translations.showMoreTransactions"
            display="link"
            @click="load"
            :disabled="isTransactionsLoadingRunning"
            v-if="isPossibleToLoadMoreTransactions"
          />
        </div>
      </template>
      <template #downloadButton>
        <bb-dropdown v-if="isDownloadButtonShown" :disabled="isDownloadInProgress" class="w-100">
          <template #trigger>
            <bb-button
              name="download-account-statement"
              :label="translations.downloadStatement"
              icon="ui-file-download-outline"
              display="blockMobileOnly"
              inverted
              :disabled="isDownloadButtonDisabled"
              :loading="isDownloadInProgress"
            />
          </template>
          <bb-dropdown-item
            v-for="(fileType) in downloadableFileTypes"
            @click="onStatementDownloadRequested(fileType)"
            :key="fileType.value"
            :disabled="fileType.isDisabled"
          >
            <div class="download-item" :data-testid="`download-item-${fileType.value}`">
              <span>{{ fileType.value }}</span> <bb-tooltip v-if="fileType.tooltip">{{ fileType.tooltip }}</bb-tooltip>
            </div>
          </bb-dropdown-item>
        </bb-dropdown>
        <div v-else class="m-t-20" />
      </template>
    </bb-transactions>
    <router-view />
  </div>
</template>

<script>
import MainTransactionFilter from '@/components/transactions/MainTransactionFilter'
import AdvancedTransactionFilter from '@/components/transactions/AdvancedTransactionFilter'
import { BbTransactions } from '@bigbank/interface-components'
import { getDateFormat } from '@/plugins/dateFormatters'
import dayjs from 'dayjs'
import { mapState } from 'pinia'
import { isEmpty, isNull, isString, isNil, isEqual, reduce, assign } from 'lodash'
import {
  MappedDirectionCode,
  TransactionSettingsItem,
  TransactionType,
  TransactionTypeCode
} from '@bigbank/dc-common/enums/loan.enums'
import { AccountStatementTransactionType } from '@bigbank/dc-common/enums/credit-card.enums'
import { isNumeric } from '@bigbank/dc-common/util'
import { useRootStore } from '../../store/root'
import { useAccountStatementStore } from '@account/store/accountStatementStore'

export default {
  components: {
    BbTransactions,
    MainTransactionFilter,
    AdvancedTransactionFilter
  },
  data () {
    return {
      viewport: null,
      filters: {
        form: {},
        formPreviousState: null
      },
      isLoading: true,
      isDownloadInProgress: false,
      isMainTransactionFiltersValid: true,
      isTransactionsLoadingRunning: false,
      totalTransactionDisplayed: 0,
      transactionsPerPage: 0,
      maxFiltersCount: 5,
      flavor: null
    }
  },
  methods: {
    // Setters
    setPeriod (period) {
      this.filters.form.period = period
      this.onPeriodChanged(period)
    },
    setIsLoading (flag) { this.isLoading = flag },
    setIsDownloadInProgress (flag) { this.isDownloadInProgress = flag },
    setTransactionsLoadingRunning (flag) { this.isTransactionsLoadingRunning = flag },
    setTotalTransactionsDisplayed (amount) { this.totalTransactionDisplayed = amount },
    setDatePickerStart (date) { this.filters.form.startDate = date },
    setDatePickerEnd (date) { this.filters.form.endDate = date },
    // Methods
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onStatementDownloadRequested (_fileType) {
      throw new Error('Implement me!')
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchAccountStatement (_opts) {
      throw new Error('Implement me!')
    },
    onTransactionItemClicked () {
      throw new Error('Implement me!')
    },
    resetFilters () {
      throw new Error('Implement me!')
    },
    isTransactionDetailsTakenFromApi (type) {
      return [
        TransactionTypeCode.SEPA2ACC_PAY,
        TransactionTypeCode.ACC2SEPA_PAY,
        TransactionTypeCode.ACC2CC_PAY_EANSS,
        TransactionTypeCode.ACC2CC_PAY_ISS,
        TransactionTypeCode.CC2ACC_PAY_EANSS,
        TransactionTypeCode.CC2ACC_PAY_ISS
      ].includes(type)
    },
    isContractNumberInTheEndRequired (type) {
      return [
        TransactionTypeCode.ACC2LOANDEBT_COLLECTION,
        TransactionTypeCode.ACC2LOANDEBT_NOTIF_FEEPEN,
        TransactionTypeCode.ACC2LOANINTPEN,
        TransactionTypeCode.ACC2LOANPROCINT,
        TransactionTypeCode.ACC2LOANTERMINT,
        TransactionTypeCode.ACC2LOANPRI2PEN,
        TransactionTypeCode.ACC2LOANOBLIGATION_INTPEN,
        TransactionTypeCode.ACC2LOANCGHANGE_FEEPEN,
        TransactionTypeCode.ACC2LOANBAILIFF_REIMBPEN,
        TransactionTypeCode.ACC2LOANCARDMONTHLY_FEE_R,
        TransactionTypeCode.ACC2LOANNOTIF_R,
        TransactionTypeCode.ACC2LOANDEBT_COLLECTION_R,
        TransactionTypeCode.ACC2LOANDEBT_NOTIF_FEEPEN_R,
        TransactionTypeCode.ACC2LOANPRI2PEN_R,
        TransactionTypeCode.ACC2LOANINTPEN_R,
        TransactionTypeCode.ACC2LOANPROCINT_R,
        TransactionTypeCode.ACC2LOANTERMINT_R,
        TransactionTypeCode.ACC2LOANOBLIGATION_INTPEN_R,
        TransactionTypeCode.ACC2LOANCGHANGE_FEEPEN_R,
        TransactionTypeCode.ACC2LOANBAILIFF_REIMBPEN_R
      ].includes(type)
    },
    async onStatementUpdateRequested () {
      try {
        this.setTotalTransactionsDisplayed(this.transactionsPerPage)
        this.setIsLoading(true)

        if (isNumeric(this.$route.query.accountID) || isNumeric(this.$route.query.cardID)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              accountID: isNumeric(this.$route.query.accountID) ? this.filters.form.accountId : undefined,
              cardID: isNumeric(this.$route.query.cardID) ? this.filters.form.cardId : undefined
            }
          })
        }

        await this.fetchAccountStatement({})

        this.captureFiltersFormState(this.filters.form)
      } finally {
        this.setIsLoading(false)
      }
    },
    async onFiltersReset () {
      try {
        this.setIsLoading(true)
        this.resetFilters()

        this.filters.form.period = null
        await this.fetchAccountStatement({
          pageNumber: 1
        })
      } finally {
        this.setIsLoading(false)
      }
    },
    async load () {
      if (this.isTransactionsLoadingRunning) {
        return
      }

      const total = this.totalTransactionDisplayed + this.transactionsPerPage
      const pageNumber = Math.floor(total / this.transactionsPerPage)

      this.setTransactionsLoadingRunning(true)
      this.setTotalTransactionsDisplayed(total)

      await this.fetchAccountStatement({ pageNumber })

      this.setTransactionsLoadingRunning(false)
    },
    onPageBottomReached (isFooterStuck) {
      if (this.isLoading) {
        return
      }
      if (this.isPossibleToLoadMoreTransactions && !isFooterStuck) {
        this.load()
      }
    },
    getTransactionStatusSuffix (transactionType) {
      return {
        [TransactionType.Reservation]: {
          name: this.translations.reserved,
          color: 'gray'
        }
      }[transactionType] ?? null
    },
    getTransactionIcon (transactionType, transactionDirectionCode) {
      return {
        [TransactionType.Reservation]: 'ui-lock-outline',
        [TransactionType.Transaction]: {
          [MappedDirectionCode.OUT]: 'ui-arrow-top-right',
          [MappedDirectionCode.IN]: 'ui-arrow-bottom-left'
        }[transactionDirectionCode] ?? 'ui-help-circle-outline'
      }[transactionType] ?? 'ui-help-circle-outline'
    },
    onPeriodChanged (value) {
      const period = this.transactionSettings?.[TransactionSettingsItem.FilteringPeriods]?.[value]

      if (!isEmpty(period) && !isNull(period.startDate) && !isNull(period.endDate)) {
        this.setDatePickerStart(new Date(period.startDate))
        this.setDatePickerEnd(new Date(period.endDate))
      }
    },
    captureFiltersFormState (filters) {
      this.filters.formPreviousState = JSON.parse(JSON.stringify(filters))
    },
    onDatePickerIntervalChanged (startDate, endDate) {
      if (isNull(startDate) || isNull(endDate)) {
        return
      }

      const pickedPeriod = Object
        .keys(this.transactionSettings?.[TransactionSettingsItem.FilteringPeriods])
        .filter((key) => {
          const period = this.transactionSettings?.[TransactionSettingsItem.FilteringPeriods][key]
          const isStartDateMatched = dayjs(startDate).format('YYYY-MM-DD') === period.startDate
          const isEndDateMatched = dayjs(endDate).format('YYYY-MM-DD') === period.endDate

          return isStartDateMatched && isEndDateMatched
        })[0]

      this.setPeriod(pickedPeriod ?? null)
    },
    checkIfAdvancedFiltersAreUsed (formState) {
      if (isNull(formState)) {
        return false
      }

      const areSomeOptionalCheckboxesUnchecked = Object
        .values(formState.additionalOptionsCheckboxes)
        .some(value => value !== true)

      const areAnyFilterFieldsFilled = formState
        .advancedSearchFilters
        .filter((filter) => (filter.filterValue ?? '').trim().length > 0)
        .length > 0

      const isContractNumberSelected = !!formState.contractId
      const isPeriodSelected = !!formState.period

      return areAnyFilterFieldsFilled || areSomeOptionalCheckboxesUnchecked || isContractNumberSelected || isPeriodSelected
    },
    onMainTransactionFilterValid (isValid) {
      this.isMainTransactionFiltersValid = isValid
    },
    getDifference (oldSearchRequest, newSearchRequest) {
      return reduce(
        assign({}, oldSearchRequest, newSearchRequest),
        (result, value, key) => {
          if (!isEqual(oldSearchRequest[key], newSearchRequest[key])) {
            result.push(key)
          }
          return result
        }, [])
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currency', 'isMobile', 'featureFlags']),
    ...mapState(useAccountStatementStore, ['isStatementOfFeesCheckboxEnabled']),
    isFiltersSubmitButtonDisabled () {
      return (this.areFilterInputsDisabled && this.isAccountFlavor) || !this.isMainTransactionFiltersValid
    },
    transactionSettings () {
      return {}
    },
    computedAccountStatement () {
      return null
    },
    translations () {
      return {
        // Transactions filters
        hideFilter: this.$pgettext('account_statement_common', 'Hide filter'),
        showFilter: this.$pgettext('account_statement_common', 'Expand filter'),
        paymentsMade: this.$pgettext('account_statement_common', 'Payments made'),
        paymentsReceived: this.$pgettext('account_statement_common', 'Payments received'),
        typeHereToSearch: this.$pgettext('account_statement_common', 'Type here to search'),
        updateStatement: this.$pgettext('account_statement_common', 'Update statement'),
        scrollToFilter: this.$pgettext('account_statement_common', 'Scroll to Filter'),
        resetButtonLabel: this.$pgettext('account_statement_common', 'Reset filter'),
        resetLabel: this.$pgettext('account_statement_common', 'You see filtered results'),
        // Transactions download
        downloadStatement: this.$pgettext('account_statement_common', 'Download statement'),
        // Transactions
        transactions: this.$pgettext('account_statement_common', 'Transactions'),
        creditTurnover: this.$pgettext('account_statement_common', 'Credit turnover'),
        debitTurnover: this.$pgettext('account_statement_common', 'Debit turnover'),
        reservations: this.$pgettext('account_statement_common', 'Reservations'),
        startingBalance: this.$pgettext('account_statement_common', 'Starting balance'),
        endBalance: this.$pgettext('account_statement_common', 'End balance'),
        showMoreTransactions: this.$pgettext('account_statement_common', 'Show more transactions'),
        reserved: this.$pgettext('account_statement_common', 'Reserved'),
        ...this.translationsOverrides
      }
    },
    translationsOverrides () {
      return {
        noTransactions: null,
        noTransactionsSimpleState: null
      }
    },
    mainTransactionFilterDropdownItems () {
      return []
    },
    isFiltered () {
      return this.featureFlags.showTransactionsResetFilterButton
        ? !this.isLoading && this.areAnyFiltersActive
        : false
    },
    isStartingBalanceVisible () {
      return true
    },
    isAvailableBalanceVisible () {
      return true
    },
    isReservationsVisible () {
      return true
    },
    bbTransactionsComponentPropsData () {
      return (() => {
        if (this.isTransactionsLoadingRunning) {
          return this.computedAccountStatement.transactions.concat(Array(this.transactionsPerPage).fill({}))
        }

        return this.computedAccountStatement.transactions
      })().map((transaction, index) => {
        const statusSuffix = this.getTransactionStatusSuffix(transaction.type)
        const amountColor = transaction.type === TransactionType.Reservation ? 'gray-50' : null
        const icon = this.getTransactionIcon(transaction?.type, transaction.transactionDirectionCode)
        const transactionCounterparty = () => {
          const value = transaction.counterpartyName ?? (transaction.description ?? '')

          return isString(value) && !this.isMobile ? value.toUpperCase() : value
        }
        const transactionDescription = () => {
          const type = transaction.transactionTypeCode

          if (isNull(transaction.counterpartyName && !isNull(transaction.description))) {
            return ''
          }

          if (this.isTransactionDetailsTakenFromApi(type)) {
            return transaction.details
          }

          if (this.isContractNumberInTheEndRequired(type)) {
            return `${transaction.description}: ${this.contractNumber}`
          }
          return transaction.description
        }

        return {
          loading: (() => {
            if (this.isLoading) {
              return true
            }
            if (index >= this.totalTransactionDisplayed - this.transactionsPerPage) {
              return this.isTransactionsLoadingRunning
            }

            return false
          })(),
          icon,
          income: transaction.transactionDirectionCode === MappedDirectionCode.IN,
          statusColumn: {
            progress: isNull(transactionCounterparty()) ? null : transactionDescription(),
            status: transactionCounterparty() ?? transactionDescription(),
            statusSuffix: statusSuffix?.name,
            statusSuffixColor: statusSuffix?.color
          },
          lastColumn: {
            date: dayjs(transaction.createdAt).format(`${getDateFormat()} | HH:mm`),
            amount: Math.abs(transaction.amount),
            amountColor
          }
        }
      })
    },
    bbTransactionsComponentProps () {
      const base = {
        title: this.translations.transactions,
        filters: true,
        creditCard: true,
        scrollToFiltersLabel: this.translations.scrollToFilter,
        filtersCollapseLabel: this.translations.hideFilter,
        filtersExpandLabel: this.translations.showFilter,
        filtersSubmitButtonLabel: this.translations.updateStatement,
        filtersSubmitButtonDisabled: this.isUpdateStatementButtonDisabled,
        creditCardBalance: { // actually its account balance, outdated naming
          startingBalance: {},
          finalBalance: {},
          reservations: {},
          debitTurnover: {},
          creditTurnover: {}
        }
      }

      if (this.isSimpleEmptyStateShown) {
        return {
          ...base,
          showEmptyState: true,
          data: [],
          filters: false,
          emptyStateText: this.translations.noTransactionsSimpleState
        }
      } else if (this.isTransactionDataMissing) {
        return {
          ...base,
          data: [],
          showEmptyState: true,
          emptyStateText: this.translations.noTransactions
        }
      }

      return {
        ...base,
        data: this.isLoading ? this.bbTransactionsLoadingMockedData : this.bbTransactionsComponentPropsData,
        loading: this.isLoading,
        iconFrameFilled: false,
        iconColor: 'gray',
        currency: this.currency ?? 'EUR',
        locale: this.locale,
        creditCardBalance: { // actually its account balance, outdated naming
          startingBalance: this.areAnyFiltersActive || !this.isStartingBalanceVisible
            ? null
            : {
                title: this.translations.endBalance,
                label: dayjs(this.computedAccountStatement.finalBalance.date).format('DD.MM.YYYY'),
                value: this.computedAccountStatement.finalBalance.value
              },
          finalBalance: this.isAvailableBalanceVisible ? this.computedStartingBalance : ' ',
          reservations: this.isReservationsVisible
            ? {
                label: this.translations.reservations,
                value: this.computedAccountStatement.reservations
              }
            : null,
          debitTurnover: {
            label: this.translations.debitTurnover,
            value: this.computedAccountStatement.debitTurnover ?? 0
          },
          creditTurnover: {
            label: this.translations.creditTurnover,
            value: this.computedAccountStatement.creditTurnover
          }
        }
      }
    },
    isTransactionDataMissing () {
      return !this.isLoading && this.bbTransactionsComponentPropsData.length === 0
    },
    isSimpleEmptyStateShown () {
      return false
    },
    isDownloadButtonShown () {
      return false
    },
    isDownloadButtonDisabled () {
      return this.isDownloadInProgress
    },
    isAccountFlavor () {
      return this.flavor === 'account'
    },
    computedStartingBalance () {
      if (this.areAnyFiltersActive) {
        return this.featureFlags.showTransactionsResetFilterButton ? {} : null
      }

      return {
        title: this.translations.startingBalance,
        label: dayjs(this.computedAccountStatement.startingBalance.date).format('DD.MM.YYYY'),
        value: this.computedAccountStatement.startingBalance.value
      }
    },
    additionalOptionsCheckboxes () {
      const isLastUncheckedCheckboxLeft = Object
        .values(this.filters.form.additionalOptionsCheckboxes)
        .filter((value) => value)
        .length === 1
      const isDisabled = (name) => {
        return this.isLoading || (isLastUncheckedCheckboxLeft && this.filters.form.additionalOptionsCheckboxes[name])
      }

      return [
        {
          label: this.translations.paymentsReceived,
          name: AccountStatementTransactionType.Credit,
          disabled: isDisabled(AccountStatementTransactionType.Credit)
        },
        {
          label: this.translations.paymentsMade,
          name: AccountStatementTransactionType.Debit,
          disabled: isDisabled(AccountStatementTransactionType.Debit)
        },
        {
          label: this.translations.reservations,
          name: AccountStatementTransactionType.Reservation,
          disabled: isDisabled(AccountStatementTransactionType.Reservation)
        }
      ]
    },
    bbTransactionsLoadingMockedData () {
      return Array(this.totalTransactionDisplayed).fill({
        icon: 'ui-check-circle-outline',
        statusColumn: {
          progress: '',
          status: ''
        }
      })
    },
    isPossibleToLoadMoreTransactions () {
      const total = this.computedAccountStatement.transactionsTotal

      return this.totalTransactionDisplayed < (total ?? this.transactionsPerPage)
    },
    datePickerMinDate () {
      return new Date(this.transactionSettings?.[TransactionSettingsItem.FilteringDatePickerLimits].min)
    },
    datePickerMaxDate () {
      return new Date(this.transactionSettings?.[TransactionSettingsItem.FilteringDatePickerLimits].max)
    },
    datePickerMaxRange () {
      return this.transactionSettings?.[TransactionSettingsItem.FilteringMaxPeriodInYears]
    },
    areFilterInputsDisabled () {
      return this.isLoading
    },
    areAnyFiltersActive () {
      return this.checkIfAdvancedFiltersAreUsed(this.filters.formPreviousState)
    },
    changeFiltersSubmitButtonLabel () {
      return this.isStatementOfFeesCheckboxEnabled ? this.translations.downloadStatementOfFees : this.translations.updateStatement
    }
  },
  mounted () {
    this.viewport = document.getElementById('main')
  },
  updated () {
    this.viewport = document.getElementById('main')
  },
  async created () {
    this.onPeriodChanged(this.transactionSettings?.[TransactionSettingsItem.FilteringPeriodByDefault])
    this.captureFiltersFormState(this.filters.form)
    this.setIsLoading(false)
  }
}
</script>

<style lang="scss">
.account-statement-transactions-wrapper {
  .bb-transactions__filters-card.bb-card--fluid {
    z-index: 5;
  }

  .bb-transaction-item__date,
  .bb-transaction-item__amount {
    z-index: auto; // fix download dropdown items appearing behind the last column
  }

  .bb-transactions__scroll-to-filters-wrapper {
    padding-top: 50px;
  }

  @media (min-width: $desktop-wide-bp) {
    .bb-transactions__scroll-to-filters-wrapper {
      padding-top: 0;
    }
  }

  .hide-balance {
    .bb-transactions__credit-card-final-balance {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.download-item {
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
}

::v-deep .bb-transactions__filters-card {
  max-width: 100%;
}
</style>
