<script>
import TransactionsWrapper from '@/components/transactions/TransactionsWrapper'
import { MY_TRANSACTIONS } from '@/const'
import { isNil, isNull } from 'lodash'
import { isNumeric } from '@bigbank/dc-common/util'
import { getEmptyFiltersFormData } from '../../../../components/transactions/transactions.helpers'
import { transformFormDataIntoSearchRequestBody } from '@credit-card/components/util/transactions.util'
import { transformDataIntoStatementOfFeesRequestBody } from '../payments/util'
import { TransactionSettingsItem } from '@bigbank/dc-common/enums/loan.enums'
import { AccountStatementFileType } from '@bigbank/dc-common/enums/credit-card.enums'
import api from '../../api'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useAccountStatementStore } from '@account/store/accountStatementStore'
import { useRootStore } from '@/store/root'
import dayjs from 'dayjs'

export default {
  name: 'account-statement',
  extends: TransactionsWrapper,
  watch: {
    isCurrentAccountSelected (value) {
      if (!value) this.isStatementOfFeesCheckboxEnabled = false
    }
  },
  data () {
    return {
      flavor: 'account',
      transactionsPerPage: MY_TRANSACTIONS.TRANSACTIONS_PER_PAGE,
      totalTransactionDisplayed: MY_TRANSACTIONS.TRANSACTIONS_PER_PAGE,
      filters: Object.assign(
        { form: getEmptyFiltersFormData('account') },
        { formPreviousState: null }
      )
    }
  },
  methods: {
    ...mapActions(useAccountStatementStore, [
      'resetAccountStatementData',
      'updateAccountStatementSearchRequest',
      'fetchAccountStatementData'
    ]),
    async fetchAccountStatement ({ pageNumber }) {
      if (!this.isAccountSelected) {
        return
      }

      const pageSize = MY_TRANSACTIONS.TRANSACTIONS_PER_PAGE
      const searchRequest = transformFormDataIntoSearchRequestBody(this.filters.form, pageSize, pageNumber)
      const diff = this.getDifference(this.accountStatementSearchRequest, searchRequest)

      this.updateAccountStatementSearchRequest(searchRequest)
      const appendResults = pageNumber > 1 && diff.includes('pageNumber') && diff.length === 1

      await this.fetchAccountStatementData(appendResults)
    },
    onTransactionItemClicked (index) {
      const transaction = this.accountStatement.transactions[index] ?? null

      if (isNull(transaction)) {
        throw new Error('Unable to open transaction, try again later')
      }

      this.$router.push(`/transaction/${transaction.id}`)
    },
    async onStatementDownloadRequested (fileTypeOption) {
      try {
        const fileType = fileTypeOption.value
        const searchRequest = transformFormDataIntoSearchRequestBody(this.filters.form, MY_TRANSACTIONS.TRANSACTIONS_PER_PAGE, 1)
        this.setIsDownloadInProgress(true)
        await api.downloadAccountStatement(searchRequest, fileType)
      } catch (err) {
        console.error(err)
      }
      this.setIsDownloadInProgress(false)
    },
    resetFilters () {
      const accountId = this.filters.form.accountId

      this.filters = {
        form: {
          ...getEmptyFiltersFormData(this.flavor),
          accountId
        },
        formPreviousState: null
      }

      this.onPeriodChanged(this.transactionSettings[TransactionSettingsItem.FilteringPeriodByDefault])
      this.captureFiltersFormState(this.filters.form)
    },
    async preselectAccount (accountID) {
      const isAccountIDAllowed = this.accountsForStatement
        .map(({ id }) => id)
        .includes(accountID)

      if (isAccountIDAllowed) {
        this.filters.form.accountId = accountID
        await this.onStatementUpdateRequested()
      }
    },
    async preselectCard (cardId) {
      let card = null
      const accountId = this.accountsForStatement.find(({ cards }) => {
        card = cards.find(({ id }) => id === cardId) ?? null

        return Boolean(card)
      })?.id ?? null

      if (!isNull(card) && !isNull(accountId)) {
        this.filters.form.accountId = accountId
        this.filters.form.cardId = cardId
        await this.onStatementUpdateRequested()
      }
    },
    async preselectContract (contractId) {
      let contract = null
      const accountId = this.accountsForStatement.find(({ contracts }) => {
        contract = contracts.find(({ id }) => id === contractId) ?? null

        return Boolean(contract)
      })?.id ?? null

      if (!isNull(contract) && !isNull(accountId)) {
        this.filters.form.accountId = accountId
        this.filters.form.contractId = contractId
        await this.onStatementUpdateRequested()
      }
    },
    async onStatementUpdateRequested () {
      try {
        if (this.isStatementOfFeesCheckboxEnabled) {
          const downloadRequest = transformDataIntoStatementOfFeesRequestBody(this.filters.form)
          await api.downloadStatementOfFees(downloadRequest)
        } else {
          this.setTotalTransactionsDisplayed(this.transactionsPerPage)
          this.setIsLoading(true)

          if (!isNil(this.$route.query.accountID)) {
            this.$router.push({
              query: {
                ...this.$route.query,
                accountID: this.filters.form.accountId
              }
            })
          }

          await this.fetchAccountStatement({})

          this.captureFiltersFormState(this.filters.form)
        }
      } catch (err) {
        this.$handleError(err)
      } finally {
        this.setIsLoading(false)
      }
    }
  },
  computed: {
    ...mapState(useAccountStatementStore, [
      'accountStatement',
      'accountsForStatement',
      'accountStatementTransactionSettings',
      'accountStatementSearchRequest'
    ]),
    ...mapWritableState(useAccountStatementStore, ['isStatementOfFeesCheckboxEnabled']),
    ...mapState(useRootStore, ['featureFlags']),
    transactionSettings () {
      return this.accountStatementTransactionSettings
    },
    computedAccountStatement () {
      return this.accountStatement
    },
    translationsOverrides () {
      return {
        noTransactions: !this.isAccountSelected
          ? this.$pgettext('EDB', 'You have not selected an account to view account statement')
          : this.$pgettext('EDB', 'Unfortunately there are no transactions or reservations matching your request. Try another one.'),
        transactions: null, // don't show title in My Transactions view,
        downloadStatementOfFees: this.$pgettext('statement_of_fees', 'Download statement')
      }
    },
    isEndDateTodayOrAfter () {
      if (!this.filters.formPreviousState) {
        return false
      }
      return !dayjs(

        this
          .filters
          .formPreviousState
          .endDate).isBefore(dayjs(), 'day')
    },
    isAvailableBalanceVisible () {
      return this.isEndDateTodayOrAfter
    },
    isReservationsVisible () {
      return this.isEndDateTodayOrAfter
    },
    isAccountSelected () {
      return !isNil(this.filters.form.accountId)
    },
    areFilterInputsDisabled () {
      return this.isLoading || !this.isAccountSelected
    },
    isSimpleEmptyStateShown () {
      return false
    },
    mainTransactionFilterDropdownItems () {
      return this.accountsForStatement
    },
    isDownloadButtonShown () {
      return [
        !this.isStatementOfFeesCheckboxEnabled,
        !this.isLoading,
        this.isAccountSelected,
        this.isMainTransactionFiltersValid,
        this.featureFlags?.disableDownloadAccountStatement !== true]
        .every(value => value === true)
    },
    downloadableFileTypes () {
      const hasAdvancedFilters = this.checkIfAdvancedFiltersAreUsed(this.filters.form)

      return [{
        value: AccountStatementFileType.PDF,
        isDisabled: false
      }, {
        value: AccountStatementFileType.ASICE,
        isDisabled: hasAdvancedFilters,
        tooltip: hasAdvancedFilters && this.$pgettext('EDB', 'You have applied additional filters, so ASICE download option is not possible')
      }]
    }
  },
  async mounted () {
    this.resetAccountStatementData()
    const cardID = this.$route.query.cardID
    const accountID = this.$route.query.accountID
    const contractID = this.$route.query.contractID

    if (isNumeric(cardID)) {
      this.preselectCard(Number(cardID))
    } else if (isNumeric(contractID)) {
      this.preselectContract(Number(contractID))
    } else if (isNumeric(accountID)) {
      this.preselectAccount(Number(accountID))
    } else {
      this.preselectAccount(this.accountsForStatement[0]?.id)
    }
  }
}
</script>
