<script>
import api from '@loan/api'
import TransactionsList from '../../../../components/TransactionsList.vue'
import transactionStatuses from '../../../../types/transaction-status'
import transactionTypes from '../../../../types/transactions'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'

export default {
  name: 'revolving-loan-transactions',
  extends: TransactionsList,
  data () {
    return {
      agreementTypeCodes: ['C'],
      types: [{
        text: this.$pgettext('money_out', 'Money Out'),
        types: [
          transactionTypes.ACC2SEPA_PAY,
          transactionTypes.DEPO_DEBIT,
          transactionTypes.ACC2INTRA_PAY
        ],
        value: 'MONEY_OUT'
      }, {
        text: this.$pgettext('money_in', 'Money In'),
        types: [
          transactionTypes.DEPO_CREDIT,
          transactionTypes.SEPA2ACC_PAY,
          transactionTypes.INTRA2ACC_PAY
        ],
        value: 'MONEY_IN'
      }, {
        text: this.$pgettext('transactions', 'Fee'),
        types: [
          transactionTypes.ACC2LOANFEE,
          transactionTypes.ACC2LOANDISBURSEMENT_FEE
        ],
        value: 'FEE',
        hideWhenNoTransactionsAvailable: true
      }],
      areFiltersShownLocal: true
    }
  },
  computed: {
    ...mapState(useLoanStore, ['revolvingLoan']),
    emptyTransactionsText () {
      return this.$pgettext('revolving_loan_dashboard', 'As soon as you have transactions, they will be available here.')
    }
  },
  methods: {
    async loadTransactions () {
      return await api.getTransactions({
        loanTypes: [InternalLoanType.REVOLVING],
        contractId: this.revolvingLoan.id,
        reserved: this.filter.transactionStatus === transactionStatuses.RESERVED || undefined
      })
    }
  },
  created () {
    window.eventBus.$on('revolving-loan__transfer', this.refresh)
  },
  beforeDestroy () {
    window.eventBus.$off('revolving-loan__transfer', this.refresh)
  }
}
</script>
