<template>
  <upsell-card
    :offer="offer"
    :currency="currency"
    :index="index"
    :count="count"
    :options="options"
    :overrideAllOptions="true"
    :title="title"
    :lead="lead"
    :agree="agree"
    @apply="$emit('apply')"
  />
</template>

<script>
import UpsellCard from './UpsellCardComponent'
import { mapState } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '../../../../store/root'

const terms = {
  et: 'https://www.bigbank.ee/content/uploads/2020/07/Bigbank_AS_Maksu_ja_tolliameti_nousolek.pdf',
  en: 'https://www.bigbank.ee/content/uploads/2020/07/Bigbank_AS_Maksu_ja_tolliameti_nousolek.pdf',
  ru: 'https://www.bigbank.ee/content/uploads/2020/07/Bigbank_AS_Maksu_ja_tolliameti_nousolek_RU.pdf'
}

export default {
  name: 'upsell-card-ee',
  components: {
    UpsellCard
  },
  props: {
    currency: {
      required: true
    },
    index: {
      required: true
    },
    count: {
      required: true
    },
    offer: {
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['language', 'locale']),
    segment () {
      return this.offer.segmentCode
    },
    translations () {
      return {
        title: {
          1: this.$pgettext('offer_card_ee', 'You have an unused loan of'),
          2: this.$pgettext('offer_card_ee', 'You now have the opportunity to apply for additional loan at significantly lower rates')
        },
        lead: {
          1: this.$pgettext('offer_card_ee', 'We will pay the loan to your account as soon as you sign the contract'),
          2: this.$pgettext('offer_card_ee', 'Check your pre-filled application and ask for a loan offer')
        },
        interestValue: {
          2: this.$pgettext('offer_card_ee', 'segment.2.interest.value')
        },
        maturityValue: {
          2: this.$pgettext('offer_card_ee', 'segment.2.maturity.value')
        },
        interest: this.$pgettext('offer_card_ee', 'Interest rate'),
        maturity: this.$pgettext('offer_card_ee', 'Maturity'),
        choose: this.$pgettext('offer_card_ee', 'Choose a Product'),
        amountAvailableLabel: this.$pgettext('offer_card_ee', 'Amount available'),
        completeApplicationLabel: this.$pgettext('offer_card_ee', 'Complete application'),
        payslipsLabel: this.$pgettext('offer_card_ee', 'Payslips'),
        noNeedText: this.$pgettext('offer_card_ee', 'No need'),
        noNeedTextPayslip: this.$pgettext('PAYSLIP_offer_card_ee', 'No need')
      }
    },
    options () {
      return [{
        title: this.translations.amountAvailableLabel.toUpperCase(),
        value: formatMoneyWithCurrency(this.amountAvailable, this.currency, this.locale),
        visible: this.segment === 1
      }, {
        title: this.translations.completeApplicationLabel.toUpperCase(),
        value: this.translations.noNeedText,
        visible: this.segment === 1
      }, {
        title: this.translations.payslipsLabel.toUpperCase(),
        value: this.translations.noNeedTextPayslip,
        visible: this.segment === 1
      }, {
        title: this.translations.interest,
        value: this.interestValue,
        visible: this.interestValue
      }, {
        title: this.translations.maturity,
        value: this.maturityValue,
        visible: this.maturityValue
      }]
    },
    amountAvailable () {
      return this.offer.upsellAmount
    },
    agree () {
      if (this.segment === 1) {
        return {
          link: terms[this.language] || terms.et
        }
      }
      return null
    },
    title () {
      return this.translations.title[this.segment]
    },
    lead () {
      return this.translations.lead[this.segment]
    },
    interestValue () {
      if ([1, 2].includes(this.segment)) {
        return this.translations.interestValue[this.segment]
      }
      return null
    },
    maturityValue () {
      if ([1, 2].includes(this.segment)) {
        return this.translations.maturityValue[this.segment]
      }
      return null
    }
  }
}
</script>
